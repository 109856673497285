import {useEffect, useRef, useState} from "react"
import Moment from 'react-moment';
import {connect} from "react-redux";
import {
    Button,
    Container,
    Row,
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Modal,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Col,
    ModalHeader,
    UncontrolledTooltip,
    Table,
    InputGroup,
    InputGroupAddon
} from "reactstrap";
import * as projectTypes from "../../constants/project.constant";
import Project from "../../Object/project.object";
import Keyword from "../../Object/keyword.object";
import ProjectEdit from "./Component/project_edit";
import AddKeyword from "./Component/add_keyword";
import * as checkStatusTypes from "../../constants/checkrankstatus.constant";
import KeywordChart from "./Component/chart";
import {FaArrowDown, FaArrowUp} from "react-icons/fa";
import {regex_domain} from "../../utility/check_rank";
import message from "@davistran86/notification"
import ExportCSV from "./Component/exportCSV";
import MultiAnalytic from "./Component/multi_analytic";
import {FLAG_IMAGES, SearchEngines} from "../../constants/SearchEngine";
import * as deviceTypes from "../../constants/Device.constant";
import {SearchDevices} from "../../constants/SearchDevice";
import HookAPI from "../../utility/HookAPI";
import {GET, POST} from "../../constants/method.constants";

const Ranking = (props) => {
    const tableRef = useRef();
    const [pageLoaded, setPageLoaded] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const [modal, setModal] = useState(false);
    const [selected_project, setSelectedProject] = useState(null)
    const [projects, setProject] = useState([]);
    const [isEditProject, setEditProject] = useState(false);
    const [isAddKeyword, setAddKeyword] = useState(false);
    const [key_check_list, setCheckList] = useState([]);
    const [keywords, setKeywords] = useState([]);
    const [check_key_overlay, setCheckOverlay] = useState(false);
    const [isOpenChart, setOpenChart] = useState(false);
    const [isOpenMultiChart, setOpenMultiChart] = useState(false);
    const [selectedKeyword, setSelectedKeyword] = useState(null);
    const [selectedEngine, setSelectedEngine] = useState(SearchEngines[31]);
    const [selectedDevice, setSelectedDevice] = useState(SearchDevices[0]);
    const [showEngine, setShowEngine] = useState(SearchEngines);
    const [searchEngineDropdown, setSearchEngineDropdown] = useState(false);
    const [searchDeviceDropdown, setSearchDeviceDropdown] = useState(false);
    const [sortCurrentKey, setSortCurrentKey] = useState(null);
    const [searchKeywords, setSearchKeywords] = useState("");
    const [loadProject, setLoadProject] = useState(false);

    useEffect(() => {
        if (!pageLoaded) {
            props.fetchProject();
            setPageLoaded(true)
            check_addon_exist();
        }
    }, [pageLoaded])

    useEffect(() => {
        setCheckOverlay(props.application.app_setting.check_rank_status == checkStatusTypes.CHECKING);
    }, [props.application.app_setting.check_rank_status])

    useEffect(() => {

        setProject([...props.project])
        if (selected_project && projects.findIndex(item => item.id === selected_project.id) == -1) {
            setSelectedProject(null)
        }
        if (selected_project && projects.findIndex(item => item.id === selected_project.id) > -1) {
            setSelectedProject(props.project[projects.findIndex(item => item.id === selected_project.id)]);
        }

    }, [props.project])

    useEffect(() => {
        setEditProject(false)
        setAddKeyword(false)
        if (selected_project != null) {
            setKeywords(selected_project.keywords)
            setSortCurrentKey(null);
            setSearchKeywords("");
            setCheckList([]);
        }
    }, [selected_project])

    useEffect(() => {
        setCheckOverlay(props.checkRankStatus.status !== checkStatusTypes.DONE)
        if (props.checkRankStatus.show_error_message) {
            message.warning({
                title: "Lỗi",
                description: "Hãy chọn công cụ tìm kiếm khác hoặc đổi ip để tiếp tục kiểm tra"
            }, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px"
            })
        }
        setCheckList([])
    }, [props.checkRankStatus])

    useEffect(() => {

        !searchEngineDropdown && findedEngine("");

    }, [searchEngineDropdown])
    useEffect(() => {
        props.change_search_engine(selectedEngine);
    }, [selectedEngine])
    useEffect(() => {

        let listkeyword = selected_project ? selected_project.keywords : [];


        listkeyword = listkeyword.filter(item => item.keyword.indexOf(searchKeywords) > -1)

        if (sortCurrentKey == "increase") {
            listkeyword = sortNullishValues(listkeyword, sortCurrentKey);
        } else if (sortCurrentKey == "descrease") {
            listkeyword = sortNullishValues(listkeyword, sortCurrentKey);
        }

        setKeywords([...listkeyword]);


    }, [sortCurrentKey, searchKeywords])


    const project_dropdown_toggle = () => setDropdown(prevState => !prevState)
    const project_toggle_modal = () => setModal(!modal);

    const project_create = (data) => {
        setModal(!modal);
        props.createProject(data)
    }

    const project_remove = () => {
        if (window.confirm("Bạn có chắc muốn xóa dự án này")) {
            props.removeProject(selected_project.id)
        }
    }

    const update_check_link = (item, action) => {

        let list = key_check_list;
        switch (action) {
            case "ADD":
                list.push(item);
                break;
            case "REMOVE":
                list = list.filter(i => item.id !== i.id)
                break;
        }
        setCheckList([...list]);

    }


    const check_addon_exist = () => {
        document.dispatchEvent(new CustomEvent("checking_addon_exist"))
    }


    const check_keyword_addon = () => {
        if (props.checkRankStatus.addon_ready) {

            let project = {...selected_project};
            project.keywords = key_check_list;

            props.checkrank_addon({
                keywords: key_check_list,
                project: selected_project
            })


        } else {

            message.warning({
                title: "Lỗi cài đặt",
                description: "Bạn chưa cài extension faceseo hoặc của cập nhật addon mới nhất. Không thể sử dụng chức năng này"
            }, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px"
            })
        }
    }

    const sortNullishValues = (arr = [], value) => {
        const assignValue = val => {
            if (val === null) {
                return Infinity;
            } else {
                return val;
            }
            ;
        };
        const sorter = (a, b) => {
            if (value == "increase") {
                return assignValue(a.current_rank) - assignValue(b.current_rank);
            } else {

                return assignValue(b.current_rank) - assignValue(a.current_rank);
            }
        };
        arr = arr.sort(sorter);
        return arr.sort(sorter);
    }

    const remove_keyword = (id) => {

        if (window.confirm("Bạn có chắc muốn xóa từ khóa này")) {
            let project = {...selected_project};
            const prepareData = {
                project_id: project.id,
                keyword_remove_id: id
            }
            props.removeKeyword(prepareData);
        }
    }

    const submit_add_keyword = (data) => {
        setAddKeyword(false);
        props.addKeyword(data);
    }
    const submit_edit_project = (data) => {
        setEditProject(false)
        props.editProject(data);
    }
    const select_all_row = (action) => {

        let list = key_check_list;

        switch (action) {
            case "ADD":
                list = keywords;
                break;
            case "REMOVE":
                list = [];

        }
        setCheckList([...list]);

    }

    const findedEngine = (value) => {
        let list = SearchEngines;
        if (value != "") {

            value = value.toLowerCase();
            list = list.filter(item => item.name.toLowerCase().indexOf(value) > -1)
        }
        setShowEngine([...list]);
    }

    const changeDevice = (value) => {
        setSelectedDevice(value);
        props.set_device_type(value);
    }


    return (

        <Container>
            <Row className={"mb-3 btn-add-project text-center"}>
                <Col>
                    <Button color={"primary w-100"} onClick={project_toggle_modal}>Thêm dự án</Button>
                </Col>
                {
                    !props.checkRankStatus.addon_ready && (
                        <Col>
                            <a className="btn btn-warning w-100"
                               href={"https://chrome.google.com/webstore/detail/faceseovietnam-43-check-s/naobaiojkmeklapkccjlpoadhignklph"}>Cài
                                đặt Extension</a>
                        </Col>
                    )

                }
            </Row>
            <Row>
                <Col>
                    <Dropdown isOpen={dropdown} toggle={project_dropdown_toggle} direction="right" className={"ps-3"}>
                        <DropdownToggle caret color={"success"}>
                            Chọn dự án
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem header>Dự án</DropdownItem>

                            {
                                projects.map((item, index) => (

                                    <DropdownItem value={item.id} key={item.id}
                                                  onClick={() => setSelectedProject(item)}>{item.project_name ? item.project_name : `Dự án ${index + 1}`}</DropdownItem>
                                ))
                            }

                            {/* <DropdownItem>Another Action</DropdownItem> */}
                            <DropdownItem divider/>
                            <DropdownItem onClick={project_toggle_modal}>Thêm dự án</DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </Col>
            </Row>
            {
                selected_project && (

                    <div className={"my-5 shadow p-3 mb-5 bg-white rounded"}>

                        <Row>
                            <h3>{`Dự án đã chọn: ${selected_project.project_name ? selected_project.project_name : "Chưa đặt tên"}`}</h3>
                        </Row>
                        <Row className={"mb-3"}><h4>{`Liên kết kiểm tra: ${selected_project.check_link}`}</h4></Row>
                        <Row>
                            <Col>
                                <Button size={"sm"} color={"success"} onClick={() => setEditProject(!isEditProject)}>Chỉnh
                                    sửa dự án</Button>
                            </Col>
                            <Col>
                                <Button size={"sm"} color={"warning"} onClick={() => setAddKeyword(!isAddKeyword)}>Thêm từ
                                    khóa mới</Button>
                            </Col>
                            <Col>
                                <Button size={"sm"} color={"danger"} onClick={() => project_remove()}>Xóa dự án</Button>
                            </Col>
                        </Row>

                        <Modal isOpen={isEditProject} toggle={() => setEditProject(!isEditProject)} size={"lg"}
                               style={{maxWidth: "1024px"}}>
                            <ModalBody>
                                <ModalHeader toggle={() => setEditProject(!isEditProject)}></ModalHeader>
                                <Row className={"my-3"}>
                                    <ProjectEdit data={selected_project} edit={submit_edit_project}/>
                                </Row>
                            </ModalBody>

                        </Modal>


                        <Modal isOpen={isAddKeyword} toggle={() => setAddKeyword(!isAddKeyword)} size={"lg"}>
                            <ModalBody>
                                <ModalHeader toggle={() => setAddKeyword(!isAddKeyword)}></ModalHeader>
                                <Row className={"my-3"}>
                                    <AddKeyword data={selected_project} submit={submit_add_keyword}/>
                                </Row>
                            </ModalBody>
                        </Modal>


                        {
                            selectedKeyword &&

                            <Modal isOpen={isOpenChart} toggle={() => setOpenChart(!isOpenChart)} size={"lg"}
                                   style={{maxWidth: "1080px"}}>
                                <ModalBody>
                                    <ModalHeader toggle={() => setOpenChart(!isOpenChart)}>Biểu đồ từ
                                        khóa: {selectedKeyword.keyword}</ModalHeader>
                                    <Row className={"my-3"}>
                                        <KeywordChart data={selectedKeyword} project_data={{...selected_project}}/>
                                    </Row>
                                </ModalBody>
                            </Modal>
                        }

                        {
                            selected_project &&

                            <Modal isOpen={isOpenMultiChart} toggle={() => setOpenMultiChart(!isOpenMultiChart)} size={"lg"}
                                   style={{maxWidth: "1080px"}}>
                                <ModalBody>
                                    <ModalHeader toggle={() => setOpenMultiChart(!isOpenMultiChart)}>So sánh thứ hạng từ
                                        khóa</ModalHeader>
                                    <Row className={"my-3"}>
                                        <MultiAnalytic data={selected_project}/>
                                    </Row>
                                </ModalBody>
                            </Modal>
                        }
                    </div>
                )
            }
            {
                selected_project && (
                    <div className={"my-5 shadow p-3 mb-5 bg-white rounded"}>

                        <Row>
                            <Col>
                                <Input type="text" value={searchKeywords}
                                       onChange={(e) => setSearchKeywords(e.target.value)}
                                       placeholder={"Tìm từ khóa..."}/>
                            </Col>
                        </Row>

                        <Table size={"sm"} className={"table-fixed"}>
                            <thead>
                            <tr>
                                <th><FormGroup check>
                                    <Label check>
                                        <Input type="checkbox"
                                               onChange={(e) => select_all_row(e.target.checked ? "ADD" : "REMOVE")}
                                               checked={key_check_list.length == keywords.length}/>
                                    </Label>
                                </FormGroup></th>
                                <th className={"col-2"}>Từ khóa</th>
                                <th>Link đang top</th>
                                <th>Hạng cũ</th>
                                <th>
                                    Hạng hiện tại
                                    <span
                                        onClick={() => setSortCurrentKey(sortCurrentKey == null ? "increase" : (sortCurrentKey != null ? (sortCurrentKey == "increase" ? "descrease" : "increase") : "increase"))}>
                                            {
                                                (sortCurrentKey == null || sortCurrentKey == "descrease") ?
                                                    <FaArrowUp/> :
                                                    <FaArrowDown/>
                                            }
                                        </span>
                                </th>
                                <th>Hạng cao nhất</th>
                                <th>Thời gian kiểm tra</th>
                                <th>Monthly search</th>
                                <th>Hành động</th>
                            </tr>
                            </thead>
                            <tbody id={"table-content"} ref={tableRef}>
                            {
                                keywords.map((item, index) => (
                                    <tr key={item.id} color={"primary"}>
                                        <td>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input key={item.id} type="checkbox"
                                                           onChange={(e) => update_check_link(item, e.target.checked ? "ADD" : "REMOVE", index)}
                                                           checked={key_check_list.includes(item)}/>
                                                </Label>
                                            </FormGroup>

                                        </td>
                                        <td className={"col-2"} onClick={() => {
                                            setSelectedKeyword(item);
                                            setOpenChart(true)
                                        }} title={"Chi tiết từ khóa"} style={{cursor: "pointer"}}>{item.keyword}</td>
                                        <td><span style={{
                                            whiteSpace: "nowrap",
                                            overflow: "hidden",
                                            textOverflow: "ellipsis",
                                            display: "block",
                                            maxWidth: "24vw"
                                        }}><a target="_blank"
                                              href={item.check_link}>{item.check_link ? item.check_link : "N/A"}</a></span>
                                        </td>
                                        <td className={`ranking-number`}>{item.old_rank ? item.old_rank : "N/A"}</td>
                                        <td className={`ranking-number ${item.current_rank === null ? `` : ((item.current_rank == 1 || (item.old_rank > item.current_rank) || (item.old_rank == null && item.current_rank != null)) ? `up-rank` : (item.old_rank == item.current_rank ? `unchange-rank` : "down-rank"))}`}>{item.current_rank ? item.current_rank : "N/A"}

                                            <span className={`ranking-arrow`}>
                                                    {
                                                        item.current_rank === null ? `` :
                                                            (item.current_rank == 1 || (item.old_rank > item.current_rank) || (item.old_rank == null && item.current_rank != null)) ?
                                                                <FaArrowUp/> :
                                                                item.old_rank == item.current_rank ?
                                                                    `` : <FaArrowDown/>
                                                    }
                                                </span>
                                        </td>
                                        <td className={`ranking-number`}>{item.best_rank ? item.best_rank : "N/A"}</td>
                                        <td>{item.modify_date ? (<Moment fromNow>{item.modify_date}</Moment>) : "N/A"}</td>
                                        <td>{item.volume ? item.volume : "N/A"}</td>
                                        <td>
                                            <Button size={"sm"} onClick={() => {
                                                setSelectedKeyword(item);
                                                setOpenChart(true)
                                            }} color={"primary"}>Biểu đồ</Button>&nbsp;
                                            <Button size={"sm"} onClick={() => remove_keyword(item.id)}>Xóa</Button>
                                        </td>

                                    </tr>
                                ))
                            }
                            </tbody>

                        </Table>
                        <hr className="dropdown-divider"/>
                        <Container fluid>

                            <Row className={"my-3 mb-5 text-center"}>

                            </Row>
                            <Row className="text-center">
                                <Col className={"mb-3"}>
                                    <Dropdown direction="up" isOpen={searchEngineDropdown}
                                              toggle={() => setSearchEngineDropdown(!searchEngineDropdown)} size={"sm"}>
                                        <DropdownToggle caret color={"danger"} outline className={"fw-bold"}>
                                            {selectedEngine ? `${selectedEngine.name} (${selectedEngine.engine})` : "Chọn ngôn ngữ"}
                                        </DropdownToggle>
                                        <DropdownMenu style={{maxHeight: "240px", overflowX: "hidden", overflowY: "auto"}}>

                                            <DropdownItem header> Ngôn ngữ</DropdownItem>
                                            <DropdownItem header><Input type="text" placeholder={"Tìm kiếm..."}
                                                                        onChange={(e) => findedEngine(e.target.value)}/></DropdownItem>
                                            {
                                                showEngine.map((item, index) => (
                                                    <DropdownItem key={item.id}
                                                                  onClick={() => setSelectedEngine(item)}><span><img
                                                        width={18} src={FLAG_IMAGES[item.id]}
                                                        style={{marginBottom: "3px"}}/></span> {` ${item.name} (${item.engine})`}
                                                    </DropdownItem>
                                                ))
                                            }
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>

                                <Col className={"mb-3"}>
                                    <Dropdown direction="up" isOpen={searchDeviceDropdown}
                                              toggle={() => setSearchDeviceDropdown(!searchDeviceDropdown)} size={"sm"}>
                                        <DropdownToggle caret color={"danger"} outline className={"fw-bold"}>
                                            {selectedDevice ? `${selectedDevice.name}` : "Chọn thiết bị"}
                                        </DropdownToggle>
                                        <DropdownMenu style={{maxHeight: "240px", overflowX: "hidden", overflowY: "auto"}}>

                                            <DropdownItem header>Thiết bị</DropdownItem>

                                            {
                                                SearchDevices.map((item, index) => (
                                                    <DropdownItem key={item.id}
                                                                  onClick={() => changeDevice(item)}>{item.name}</DropdownItem>
                                                ))
                                            }
                                        </DropdownMenu>
                                    </Dropdown>
                                </Col>
                                <Col className={"mb-3"}>
                                    {

                                        check_key_overlay ?
                                            <Button size={"sm"}>
                                                <div className="spinner-border" role="status">
                                                </div>
                                            </Button> :
                                            <Button color={"info"} onClick={() => check_keyword_addon()} outline
                                                    className="fw-bold" size={"sm"}>Kiểm tra</Button>

                                    }
                                </Col>

                                <Col className={"mb-3"}>
                                    {

                                        !check_key_overlay &&
                                        <Button size={"sm"} className="fw-bold" type="button" color={"info"} outline
                                                onClick={() => setOpenMultiChart(!isOpenMultiChart)}
                                                data={selected_project}>So sánh thứ hạng</Button>

                                    }
                                </Col>
                                <Col className={"mb-3"}>
                                    {

                                        !check_key_overlay &&
                                        <ExportCSV csvData={selected_project} fileName={"check rank keyword"}/>

                                    }
                                </Col>

                            </Row>
                        </Container>

                    </div>
                )
            }

            <ModalRanking modal={modal} toggle={project_toggle_modal} submit={project_create}/>
        </Container>
    )
}

const ModalRanking = (props) => {

    const [project_name, setProjectName] = useState("");
    const [project_link, setProjectLink] = useState("");
    const [project_keyword, setProjectKeyword] = useState("");

    const submitProject = () => {
        if (project_link != null && project_link != "") {

            let keys = project_keyword.split("\n");
            keys = keys.filter((value) => value !== "");
            keys = keys.filter((value, index, self) => self.indexOf(value) === index)

            const keylist = [];
            keys.forEach(key => {
                keylist.push(new Keyword("", key, null, null, null, null, null));
            })

            let project = new Project("", project_name, project_link, null, keylist, null);
            props.submit(project);
        } else {

            message.warning({title: "Lỗi", description: "Hãy nhập và kiểm tra domain!"}, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px"
            })
        }
    }

    const projectLink = () => {
        const domain = regex_domain(project_link);
        if (domain != false) {
            message.success({title: "Hệ thống", description: "Domain đã đúng"}, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px"
            })
            setProjectLink(domain);
        } else {
            message.warning({title: "Lỗi domain", description: "Domain phải có dạng abc.xyz"}, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px"
            })
        }
    }

    return (


        <Modal isOpen={props.modal} toggle={props.toggle} size={"lg"}>
            <ModalHeader toggle={props.toggle}>Tạo dự án mới</ModalHeader>
            <ModalBody>

                <Form>
                    <FormGroup row className={"mb-3"}>
                        <Label for="exampleEmail" sm={2}>Tên dự án</Label>
                        <Col sm={10}>
                            <Input type="text" name="email" placeholder="Tên dự án..."
                                   onChange={(e) => setProjectName(e.target.value)}/>
                        </Col>
                    </FormGroup>
                    <FormGroup row className={"mb-3"}>
                        <Label for="examplePassword" sm={2}>Domain kiểm tra</Label>
                        <Col sm={10}>
                            <div>
                                <InputGroup>
                                    <Input type="text" name="project_link" placeholder="ví dụ: example.com"
                                           id={"UncontrolledTooltipProjectLink"}
                                           onChange={(e) => setProjectLink(e.target.value)} value={project_link}/>
                                    <InputGroupAddon addonType="append">
                                        <Button type="button" color="success" onClick={() => projectLink()}>Kiểm tra
                                            domain</Button>
                                    </InputGroupAddon>
                                </InputGroup>

                                <UncontrolledTooltip placement="right" target="UncontrolledTooltipProjectLink"
                                                     placement={"top"}>
                                    Kiểm tra domain để chắc chắn kiểm tra đúng
                                </UncontrolledTooltip>
                            </div>
                        </Col>
                    </FormGroup>
                    <FormGroup row className={"mb-3"}>
                        <Label for="exampleText" sm={2}>Từ khóa</Label>
                        <Col sm={10}>
                            <Input type="textarea" name="text" id="UncontrolledTooltipExample"
                                   onChange={(e) => setProjectKeyword(e.target.value)}/>
                            <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample"
                                                 placement={"top"}>
                                Mỗi từ khóa nằm trên 1 dòng
                            </UncontrolledTooltip>
                        </Col>
                    </FormGroup>
                </Form>

            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => submitProject()}>Tạo</Button>{' '}
                <Button color="secondary" onClick={props.toggle}>Thoát</Button>
            </ModalFooter>
        </Modal>

    )
}
const mapStateToProps = (state) => ({
    project: state.ProjectRepository,
    application: state.ApplicationRepository,
    checkRankStatus: state.CheckStatusRepository
})
const mapDispatchToProps = {
    fetchProject: () => ({
        type: projectTypes.FETCH_PROJECTS,
        data: null
    }),
    checkrank: (data) => ({
        type: projectTypes.CHECK_KEY_PROJECT,
        data: data
    }),
    createProject: (data) => ({
        type: projectTypes.CREATE_PROJECT,
        data: data
    }),
    editProject: (data) => ({
        type: projectTypes.EDIT_PROJECT,
        data: data
    }),
    addKeyword: (data) => ({
        type: projectTypes.ADD_KEYWORD,
        data: data
    }),
    removeKeyword: (data) => ({
        type: projectTypes.REMOVE_KEYWORD,
        data: data
    }),
    removeProject: (data) => ({
        type: projectTypes.REMOVE_PROJECT,
        data: data
    }),
    checkrank_addon: (data) => ({
        type: checkStatusTypes.INIT_CHECK_RANK,
        data: data
    }),
    change_search_engine: (data) => ({
        type: checkStatusTypes.CHANGE_SEARCH_ENGINE,
        data: data
    }),
    show_message_complete: () => ({
        type: checkStatusTypes.DETECT_ADDON_CAN_NOT_CHECK_DONE,
        data: null
    }),
    set_device_type: (data) => ({
        type: checkStatusTypes.CHANGE_SEARCH_DEVICE,
        data: data
    })
};

export default connect(mapStateToProps, mapDispatchToProps)(Ranking)