import { useState } from "react"
import { Button, Col, Container, Row } from "reactstrap";
import { Line } from 'react-chartjs-2';
import { useEffect } from "react";
import Notes from "./Notes";

const KeywordChart = ({...props}) => {
    const [data, setData] = useState(props.data);
    const [showdata, setShowData] = useState({});

    useEffect(() => {

        let format_data = []
        data.chart.forEach((item, index) => {

            let date = new Date(item.date);
            let show_date = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

            format_data.push([show_date, item.rank])
        })

        setShowData({
            // labels: ['Thứ hạn'],
            datasets: [{
                label: 'Thứ hạng',
                data: format_data,
                // borderColor:"red",
                backgroundColor: "red"
            }]
        }
        )

    }, [data])
    useEffect(() => {
        setData(props.data)
    }, [props.data])

    const options = {
        scales: {
            y: {
                ticks: {
                    beginAtZero: true,
                    reverse: true,
                    backdropPadding: {
                        x: 10,
                        y: 10
                    }
                },

                reverse: true,
                max: 100,
                step: 10,
                min: 0
            },
        },
        interaction: {
            intersect: false,
        }
    };

    return (

        <Container fluid>

            <Row className={"mb-3  shadow-lg p-3 mb-5 bg-white rounded"}>

                <Container className={"w-100"} style={{ height: "500px" }}>
                    <Line data={showdata} options={options} />
                </Container>
            </Row>
            
            <Row className={"mt-5"}>
                <Notes keyword={data} project_data={props.project_data} />
            </Row>
        </Container>

    )
}

export default KeywordChart;