import React from "react";
import { Link } from "react-router-dom";
const Breadcrumbs = ({ crumbs }) => {
    // Don't render a single breadcrumb.
    if (crumbs.length <= 1) {
        return null;
    }
    return (
        <div>
            <ol className="breadcrumb">
                {crumbs.map(({ name, path,sub }, key) =>
                    key + 1 === crumbs.length ? (
                        <li key={key} className="breadcrumb-item active" aria-current="page">
                            <span key={key}>
                                    {name} {sub ? `(${sub})` : ""}
                            </span>
                        </li>
                    ) : (
                            <li key={key} className="breadcrumb-item active" aria-current="page">
                                <Link key={key} to={path}>
                                    {name} {sub ? `(${sub})` : ""}
                                </Link>
                            </li>
                        )
                )}
            </ol>
        </div>
    );
};
export default Breadcrumbs;