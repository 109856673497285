interface IUser {
    id: string;
    name: string;
    role: string;
    email: string;

}

export default class User implements IUser {
    public id: string;
    public name: string;
    public role: string;
    public email: string;

    constructor(
        id: string,
        name: string,
        role: string,
        email: string) {
        this.id = id;
        this.name = name;
        this.role = role;
        this.email = email;
    }
}