import { ActionWrapper } from "../../Object/redux.object";
import * as check_type from "../../constants/checkrankstatus.constant";
import CheckRank from "../../Object/check_rank.object";
import { SearchEngines } from "../../constants/SearchEngine";
import { SearchDevices } from "../../constants/SearchDevice";

const CheckStatusRepository = (state: CheckRank = new CheckRank([], check_type.DONE, null, null, false, SearchEngines[0],false,SearchDevices[0]), action: ActionWrapper) => {

    const check_rank = () => {
        let curr_key_index = -1;
        if (state.status === check_type.CAPTCHA_ERROR) {
            return state;
        }
        if (state.current_keyword) {
            curr_key_index = state.check_keywords.indexOf(state.current_keyword);
        }
        if (state.status == check_type.CHECKING_AFTER_CAPTCHA) {
            curr_key_index -= 1;
        }

        if (curr_key_index === state.check_keywords.length - 1) {
            state.status = check_type.DONE;
        } else {
            state.current_keyword = state.check_keywords[curr_key_index + 1];
            var evt = new CustomEvent("send_request_rank", {
                bubbles: true,
                detail: {
                    id: state.current_keyword.id,
                    keyword: state.current_keyword.keyword,
                    mobile: state.device.code,
                    is_captcha: false,
                    project: state.project,
                    engine: state.engine
                }
            })
            state.status = check_type.CHECKING;
            document.dispatchEvent(evt)
        }
        return state;
    }



    switch (action.type) {

        case check_type.INIT_CHECK_RANK:
            state.show_error_message = false;
            state.check_keywords = action.data.keywords;
            state.current_keyword = null;
            state.status = check_type.INIT_CHECK;
            state.project = action.data.project;
            state = check_rank();
            return { ...state }
        case check_type.CONTINOUS_CHECK_RANK:
            state = check_rank();
            return { ...state }
        case check_type.CAPTCHA_ERROR:
            state.status = check_type.CAPTCHA_ERROR;
            return state;
        case check_type.CAPTCHA_SUCCESS:
            state.status = check_type.CHECKING_AFTER_CAPTCHA;
            state = check_rank();
            return state;
        case check_type.CHECKING:
            return { ...state };
        case check_type.DONE:
            return { ...state };
        case check_type.CHANGE_SEARCH_ENGINE:
            state.engine = action.data;
            return { ...state };
        case check_type.DETECT_ADDON_SUCCESS:
            state.addon_ready = true;
            return { ...state };
        case check_type.DETECT_ADDON_CAN_NOT_CHECK:
            state.show_error_message = true;
            state.status = check_type.DONE;
            return { ...state }
        case check_type.DETECT_ADDON_CAN_NOT_CHECK_DONE:
            state.show_error_message = false;
            return { ...state }
        case check_type.CHANGE_SEARCH_DEVICE:
            state.device = action.data;
            return { ...state }
        default:
            return { ...state };
    }
}

export default CheckStatusRepository;