import { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
import * as spinnerTypes from "../../constants/Spinner.constant";
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
// import { Editor } from 'react-draft-wysiwyg';
import { Button, Container, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, Col, ModalHeader } from "reactstrap";
import CreateDataSpin from "./Component/create_data";
import SpinManager from "./Component/manager_data";
import { fillSpinData, spinDataPreview } from "../../utility/Spindata";
import message from "@davistran86/notification"

const SpinnerText = (props) => {
    const editorRef = useRef(null);
    const [pageLoaded, setPageLoaded] = useState(false)
    const [spinners, setSpinners] = useState([])
    const [isOpenModalCreate, setOpenModalCreate] = useState(false);
    const [isOpenModalManage, setOpenModalManage] = useState(false);
    const [selected_spinner, SetSelectedSpinner] = useState(null);
    const [spin_html_data, setSpindata] = useState(null);
    const [editor_raw_data, setRawData] = useState(null);

    const [dropdown, setDropdown] = useState(false);

    const project_dropdown_toggle = () => setDropdown(prevState => !prevState)

    useEffect(() => {

        setSpinners([...props.spinners]);
        if (selected_spinner != null) {
            let finded = props.spinners.find(e => e.id === selected_spinner.id);
            if (finded != null && finded != undefined) {
                SetSelectedSpinner({ ...finded })
            } else {
                SetSelectedSpinner(null)
            }
        } else {

            SetSelectedSpinner({ ...props.spinners.find(e => e.id === "f666c838-8c1d-4608-aa8f-2ea5481d7a4c") });
        }
        setOpenModalCreate(false)
        setOpenModalManage(false)



    }, [props.spinners])

    useEffect(() => {

        if (!pageLoaded) {
            props.fetchSpinData()
            setPageLoaded(true)
        }

    }, [pageLoaded])

    const prepare_spin_keyword = () => {

        if (selected_spinner == null) {
            message.warning({ title: "Thông báo", description: "Chọn dữ liệu" }, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px"
            })
            return;
        }
        const raw_text = "<div>" + editor_raw_data + "</div>";

        const result = fillSpinData(raw_text, selected_spinner.data);
        console.log("<div>" + result + "</div>");

        setRawData("<div>" + result + "</div>")



    }

    const spin_keyword = () => {
        const result = spinDataPreview("<div>" + editor_raw_data + "</div>")
        setSpindata("<div>" + result + "</div>");
    }




    return (
        <Container>
            <Row className={"my-5 shadow p-3 mb-5 bg-white rounded"} >
                <div
                >
                    <CKEditor
                        ref={editorRef}
                        editor={ClassicEditor}
                        data={editor_raw_data}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setRawData(data);
                        }}
                    />
                </div>
            </Row>

            <Row className={"shadow p-3 mb-3 bg-white rounded text-center"} >
                <Container fluid>

                    <Row>
                        <Col className={"border-end"}>
                            <Button color={"success"} onClick={() => setOpenModalManage(!isOpenModalManage)}>Quản lí dữ liệu spin</Button>
                        </Col>
                        <Col>
                            <Button color={"success"} onClick={() => setOpenModalCreate(!isOpenModalCreate)}>Tạo mới dữ liệu</Button>
                        </Col>
                    </Row>
                </Container>

            </Row>
            <Row className={"shadow p-3 mb-3 bg-white rounded"} >
                <Col>

                    <Dropdown isOpen={dropdown} toggle={project_dropdown_toggle} direction="right">
                        <DropdownToggle caret color={"success"} outline>
                            {selected_spinner && selected_spinner.name ? "Dữ liệu: " + selected_spinner.name : "Chọn dữ liệu"}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem header>Dữ liệu</DropdownItem>
                            {
                                spinners.map((item, index) => (

                                    <DropdownItem value={item.id} key={item.id} onClick={() => SetSelectedSpinner(item)}>{item.name ? item.name : `Dự án ${index + 1}`}</DropdownItem>
                                ))
                            }
                        </DropdownMenu>
                    </Dropdown>
                </Col>
                <Col>
                    <Button onClick={() => prepare_spin_keyword()} color={"primary"} outline>Tạo cấu trúc spin</Button>
                </Col>
                <Col className={"text-end"}>
                    <Button onClick={() => spin_keyword()} color={"success"} outline>Xuất bài viết mới</Button>
                </Col>
            </Row>
            {
                spin_html_data && (
                    <Row className={"my-5 shadow p-3 mb-5 bg-white rounded"} >
                        <div>
                            <CKEditor
                                data={spin_html_data}
                                editor={ClassicEditor}
                            />
                        </div>
                    </Row>
                )
            }
            <Modal isOpen={isOpenModalCreate} toggle={() => setOpenModalCreate(!isOpenModalCreate)} size={"lg"}>
                <ModalBody>
                    <ModalHeader toggle={() => setOpenModalCreate(!isOpenModalCreate)}>Tạo bộ từ khóa</ModalHeader>
                    <Row className={"my-3"}>
                        <CreateDataSpin submit={props.createSpinData} />
                    </Row>
                </ModalBody>
            </Modal>

            <Modal isOpen={isOpenModalManage} toggle={() => setOpenModalManage(!isOpenModalManage)} size={"lg"}>
                <ModalBody>
                    <ModalHeader toggle={() => setOpenModalManage(!isOpenModalManage)}>Quản lý bộ từ khóa</ModalHeader>
                    <Row className={"my-3"}>
                        <SpinManager />
                    </Row>
                </ModalBody>
            </Modal>
        </Container>
    )

}

const mapStateToProps = (state) => ({
    spinners: state.SpinnerRepository
})

const mapDispatchToProps = {

    fetchSpinData: () => ({
        type: spinnerTypes.FETCH_SPINNER,
        data: null
    }),
    createSpinData: (data) => ({
        type: spinnerTypes.CREATE_SPINNER_DATA,
        data: data
    }),
    editSpinData: (data) => ({
        type: spinnerTypes.EDIT_SPINNER_DATA,
        data: data
    }),
    removeSpinData: (data) => ({
        type: spinnerTypes.REMOVE_SPINNER_DATA,
        data: data
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(SpinnerText)