import { useState } from "react";
import { Button, Container, Row, Form, FormGroup, Label, Input, Col} from "reactstrap";
import Spinner from "../../../Object/Spinner.object";

const CreateDataSpin = (props) => {

    const [name,setName] = useState("");
    const [data,setData] = useState("");

    const create_data = () => {
        const update_data = new Spinner("",name,null,data,"","","");
        props.submit(update_data)
    }


    return (
        <Container fluid>
            <Row><Col><h3>Thêm dữ liệu mới</h3></Col></Row>
            <Row>
                <Form>
                    <FormGroup row className={"mb-3"}>
                        <Label for="example_name" sm={4}>Tên dữ liệu</Label>
                        <Col sm={8}>
                            <Input type="text" name="example_name" placeholder="Tên dữ liệu..." value={name} onChange={(e) => setName(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row className={"mb-3"}>
                        <Label for="example_link" sm={4}>Dữ liệu</Label>
                        <Col sm={8}>
                            <Input type="textarea" name="example_link" placeholder="example: {từ đồng nghĩa 1|từ đồng nghĩa 2|từ đồng nghĩa 3|...}" value={data} onChange={(e) => setData(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Button onClick={() => create_data()} color={"success"}>Lưu dữ liệu</Button>
                    </FormGroup>
                </Form>
            </Row>
        </Container>
    )
}

export default CreateDataSpin