
import { Button } from 'reactstrap';
import Moment from 'react-moment';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { useState } from 'react';

const ExportCSV = ({ csvData, fileName }) => {

    const [data, setData] = useState(null);

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const timeConverter = (UNIX_timestamp) => {
        var a = new Date(UNIX_timestamp);
        var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        var year = a.getFullYear();
        var month = months[a.getMonth()];
        var date = a.getDate();
        var hour = a.getHours();
        var min = a.getMinutes();
        var sec = a.getSeconds();
        var time = date + "/" + (a.getMonth()+1) +"/"+year +" " + hour + ':' + min + ':' + sec;// date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec;
        return time;
    }

    const prepareData = (data) => {

        const listKeywords = data.keywords;
        let returnList = [];
        listKeywords.forEach((item, index) => {


            returnList.push({
                id: index + 1,
                keyword: item.keyword,
                link: item.check_link,
                old_rank: item.old_rank,
                new_rank: item.current_rank,
                best_rank: item.best_rank,
                check_time: timeConverter(item.modify_date),
                volume: item.volume
            });

        })
        return returnList;

    }


    const exportToCSV = (csvData, fileName) => {

        const dataProcessed = prepareData(csvData);
        // console.log(dataProcessed);
        // const ws = XLSX.utils.json_to_sheet(dataProcessed, { skipHeader: true, origin: "A3" });



        // const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const merge = [
            {
                s: {
                    r: 0, c: 0
                },
                e: {
                    r: 0, c: 6
                }
            }
        ]

        const tableHeader = [["KIỂM TRA TỪ KHÓA"]]

        const Header = [["#", "Từ khóa", "Đường dẫn", "Hạng cũ", "Hạng hiện tại", "Hạng cao nhất", "Thời gian kiểm tra","Lượt tìm kiếm trung bình"]];


        const ws = XLSX.utils.book_new();
        ws["!merges"] = merge
        XLSX.utils.sheet_add_aoa(ws, tableHeader, { origin: 'A1' });
        XLSX.utils.sheet_add_aoa(ws, Header, { origin: 'A2' });

        fileName += "Date_[" + timeConverter((new Date().getTime())) + "]";
        //Starting in the second row to avoid overriding and skipping headers
        XLSX.utils.sheet_add_json(ws, dataProcessed, { origin: 'A3', skipHeader: true });

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, fileName + fileExtension);
    }

    return (
        <Button color={"info"} onClick={(e) => exportToCSV(csvData, fileName)} outline className="fw-bold">Xuất kết quả</Button>
    )
}
export default ExportCSV;