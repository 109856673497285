import axios from 'axios';
import * as methodTypes from '../constants/method.constants';
import message from "@davistran86/notification"
import Cookies from "js-cookie";
const utility = require("../config/utility.json");
export default function HookAPI(endpoint, data = null, method = null, showMessage = false) {
    axios.defaults.withCredentials = true;
    const baseAPI = utility.ROOT_PAGE;
    let header = {};
    if (Cookies.get("_tkl")) {
        header = {
            "Authorization": "Bearer " + Cookies.get("_tkl")
        }
    }
    if (method === methodTypes.GET || method === null) {
        return axios({
            method: methodTypes.GET,
            url: baseAPI + endpoint,
            headers: header
        }).then(
            response => {
                const responseData = response.data;
                if (showMessage)
                    message.success({ title: "Thông báo", description: responseData.message }, {
                        width: Math.min(window.innerWidth * 0.8, 400) + "px"
                    })
                return [responseData, null];
            }
        ).catch(error => {
            if (error.response) {
                const errorResponse = error.response.data;
                message.error({ title: "Lỗi", description: errorResponse.data }, {
                    width: Math.min(window.innerWidth * 0.8, 400) + "px"
                })
            }
            return [null, error];

        }

        );


    } else {
        return axios(baseAPI + endpoint, {
            method: method,
            data: data,
            headers: header
        }).then(response => {
            const responseData = response.data;
            if (showMessage)
                message.success({ title: "Thông báo", description: responseData.message }, {
                    width: Math.min(window.innerWidth * 0.8, 400) + "px"
                })
            return [responseData, null];
        }
        ).catch(error => {
            if (error.response) {
                const errorResponse = error.response.data;
                message.error({ title: "Lỗi", description: errorResponse.data }, {
                    width: Math.min(window.innerWidth * 0.8, 400) + "px"
                })
            }
            return [null, error];

        });
    }

}