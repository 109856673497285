interface ISearchDevice {
    id: Number
    name: String
    code: String


}

export default class SearchDevice implements ISearchDevice {
    public id: Number;
    public name: String;
    public code: String;

    constructor(
        id: Number,
        name: String,
        code: String) {
        this.id = id;
        this.name = name;
        this.code = code;
    }
}