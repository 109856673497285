export const CHECKING = "CHECKING"
export const CHECKING_AFTER_CAPTCHA = "CHECKING_AFTER_CAPTCHA"
export const DONE = "DONE"
export const INIT_CHECK = "INIT_CHECK"
export const INIT_CHECK_RANK = "INIT_CHECK_RANK"
export const CHECK_RANK = "CHECK_RANK"
export const CAPTCHA_ERROR = "CAPTCHA_ERROR"
export const CAPTCHA_SUCCESS = "CAPTCHA_SUCCESS"
export const CONTINOUS_CHECK_RANK = "CONTINOUS_CHECK_RANK"
export const ADDON_CHECKING = "ADDON_CHECKING"
export const CHANGE_SEARCH_ENGINE = "CHANGE_SEARCH_ENGINE"
export const CHANGE_SEARCH_DEVICE = "CHANGE_SEARCH_DEVICE"
export const DETECT_ADDON_SUCCESS = "DETECT_ADDON_SUCCESS"
export const DETECT_ADDON_CAN_NOT_CHECK = "DETECT_ADDON_CAN_NOT_CHECK"
export const DETECT_ADDON_CAN_NOT_CHECK_DONE = "DETECT_ADDON_CAN_NOT_CHECK"