import { connect } from "react-redux"
import { Button, Card, CardBody, CardText, CardTitle, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";
import ranking from "../../image/ranking_base_image.png"
import planner from "../../image/planner_base_image.jpg"
import entity_creator from "../../image/entity_creator.png"
import spinner from "../../image/spin_keyword.jpg"
import webTraffic from "../../image/web_traffic.jpg";
import phone_convert from "../../image/convert_facebook_phone.png";
import { CHECK_RANK_KEYWORD } from "../../constants/application.constant";

const Home = (props) => {



    return (

        <Container>
            <Row className={"mb-3"}>
                <Col>
                    <Card>
                        <Row>
                            <Col md={4}>
                                <img src={ranking} className={"w-100 h-100 rounded"} />
                            </Col>
                            <Col md={8}>
                                <CardBody>
                                    <CardTitle tag="h3">Kiểm tra thứ hạng từ khóa</CardTitle>
                                    <CardText>Thao tác từ khóa, lập kế hoạch theo dõi thứ hạng từ khóa khi SEO</CardText>
                                    <Link to={"/ranking"} ><Button color="success" className={"end-btn fw-bold"}>Kiểm tra thứ hạng</Button></Link>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>

            <Row className={"mb-3"}>
                <Col>
                    <Card>
                        <Row>
                            <Col md={4}>
                                <img src={planner} className={"w-100 h-100 rounded"} />
                            </Col>
                            <Col md={8}>
                                <CardBody>
                                    <CardTitle tag="h3">Lập kế hoạch từ khóa</CardTitle>
                                    <CardText>Thao tác từ khóa, kiểm tra giá trị CPC của từ khóa khi quảng cáo trên google</CardText>
                                    <Link to={"/planner"} ><Button color="success" className={"end-btn fw-bold"}>Kế hoạch từ khóa</Button></Link>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className={"mb-3"}>
                <Col>
                    <Card>
                        <Row>
                            <Col md={4}>
                                <img src={entity_creator} className={"w-100 h-100 rounded"} />
                            </Col>
                            <Col md={8}>
                                <CardBody>
                                    <CardTitle tag="h3">Auto Tạo Entity Giúp Bạn?</CardTitle>
                                    <CardText>Không cần cắm mặt vào máy, chỉ việc đi nhậu ăn chơi từ khóa top nhanh</CardText>
                                    <a target="_blank" href={"https://zalo.me/0795921361"}><Button color="success"
                                                                                           className={"end-btn fw-bold"}>Sử dụng ngay</Button></a>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className={"mb-3"}>
                <Col>
                    <Card>
                        <Row>
                            <Col md={4}>
                                <img src={spinner} className={"w-100 h-100 rounded"} />
                            </Col>
                            <Col md={8}>
                                <CardBody>
                                    <CardTitle tag="h3">Tạo bài vệ tinh</CardTitle>
                                    <CardText>Từ bài viết chính của bạn, công cụ sẽ tạo ra một bài mới với ý nghĩa tương tự nhưng với từ ngữ khác</CardText>
                                    <Link to={"/spin"} ><Button color="success" className={"end-btn fw-bold"}>Tạo bài vệ tinh</Button></Link>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className={"mb-3"}>
                <Col>
                    <Card>
                        <Row>
                            <Col md={4}>
                                <img src={webTraffic} className={"w-100 h-100 rounded"} />
                            </Col>
                            <Col md={8}>
                                <CardBody>
                                    <CardTitle tag="h3">Tool tăng traffic</CardTitle>
                                    <CardText>Công cụ dùng để tăng lượt truy cập trang web số lượng thực</CardText>
                                    <a target="_blank" href={"https://faceseo.vn"}><Button color="success" className={"end-btn fw-bold"}>Tăng traffic cho web</Button></a>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Row className={"mb-3"}>
                <Col>
                    <Card>
                        <Row>
                            <Col md={4}>
                                <img src={phone_convert} className={"w-100 h-100 rounded"} />
                            </Col>
                            <Col md={8}>
                                <CardBody>
                                    <CardTitle tag="h3">Tool Chuyển đổi đầu số điện thoại</CardTitle>
                                    <CardText>Chuyển đổi đầu số và format số điện thoại cho đúng format để có tệp số điện thoại chạy facebook Ads theo cấu trúc (+84)</CardText>
                                    <Link to={"/phoneformat"} ><Button color="success" className={"end-btn fw-bold"}>Format số điện thoại</Button></Link>
                                </CardBody>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            {/* <Row>
                <Button onClick={() => props.test()}>CHECK_RANK_KEYWORD</Button>
            </Row> */}
        </Container>


    )
}

const mapDispatchToProps = ({
    test: () => ({
        type: CHECK_RANK_KEYWORD,
        data: null
    })
})

export default connect(null, mapDispatchToProps)(Home)