import { DESKTOP } from "../constants/Device.constant";
import Keyword from "./keyword.object";
import Project from "./project.object";
import SearchDevice from "./SearchDevice.object";
import SearchEngine from "./SearchEngine.object";

interface ICheckRank {

    check_keywords: Keyword[];
    status: String;
    current_keyword: Keyword | null;
    project: Project | null;
    addon_ready: Boolean,
    engine: SearchEngine,
    show_error_message: Boolean
    device: SearchDevice
}

export default class CheckRank implements ICheckRank {
    public check_keywords: Keyword[];
    public status: String;
    public current_keyword: Keyword | null;
    public project: Project | null;
    public addon_ready: Boolean;
    public engine: SearchEngine;
    public show_error_message: Boolean;
    public device: SearchDevice

    constructor(

        check_keywords: Keyword[],
        status: String,
        current_keyword: Keyword | null,
        project: Project | null,
        addon_ready: Boolean,
        engine: SearchEngine,
        show_error_message: Boolean = false,
        device: SearchDevice) {
        this.check_keywords = check_keywords;
        this.status = status;
        this.current_keyword = current_keyword;
        this.project = project;
        this.addon_ready = addon_ready;
        this.engine = engine;
        this.show_error_message = show_error_message;
        this.device = device;
    }
}