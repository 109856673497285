import { all, fork, put, takeLatest } from "redux-saga/effects";
import { ActionWrapper } from "../../Object/redux.object";
import * as applicationTypes from "../../constants/application.constant"



function* watchCheckLinkAsync(action:ActionWrapper){

    
    var evt = new CustomEvent("send_request_rank",{
        bubbles:true,
        detail: {
            id:1,
            keyword:"dao tao seo",
            is_mobile: false,
            is_captcha:false
        }
    }) 
    document.dispatchEvent(evt)

}

function* watchCheckLink() {
    yield takeLatest(applicationTypes.CHECK_RANK_KEYWORD,watchCheckLinkAsync)
}


export default function* checkLinkSaga() {
    yield all([
        fork(watchCheckLink)
    ])
}