import Keyword from "./keyword.object";
import User from "./User.object";

interface IProject {
    id: string;
    project_name: string;
    check_link: string;
    create_time: string;
    keywords: Keyword[];
    owner: User


}


export default class Project implements IProject {
    public id: string;
    public project_name: string;
    public check_link: string;
    public create_time: string;
    public keywords: Keyword[];
    public owner: User

    constructor(
        id: string,
        project_name: string,
        check_link: string,
        create_time: string,
        keywords: Keyword[],
        owner: User) {
        this.id = id;
        this.project_name = project_name;
        this.check_link = check_link;
        this.create_time = create_time;
        this.keywords = keywords;
        this.owner = owner;
    }
}