import { useEffect, useState } from "react";
import { Button, Container, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Modal, ModalBody, ModalFooter, Form, FormGroup, Label, Input, Col, ModalHeader, UncontrolledTooltip, Table } from "reactstrap";
import { POST } from "../../constants/method.constants";
import HookAPI from "../../utility/HookAPI";

const Planner = (props) => {
    const [pageLoaded, setPageLoaded] = useState(false);
    const [list_search, setListSearch] = useState([]);
    const [keyword, setKeyword] = useState("");
    const [base_link, setBaseLink] = useState("");

    const check_ads_key = () => {
        let keys = keyword.split("\n");
        keys = keys.filter((value) => value !== "");
        keys = keys.filter((value, index, self) => self.indexOf(value) === index)

        const keylist = [];
        keys.forEach(key => {
            keylist.push(key);
        })

        HookAPI(`ranking/keyword/planner?url=${base_link}`, keylist, POST).then(([res, error]) => {
            if (res) {
                setListSearch(res.data);
            }
        })

    }
    const makeVND = (number) => {
        let num = number / 1000000;
        num = Math.round(num)
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }


    return (
        <Container>
            <Row className={"my-5 shadow p-3 mb-5 bg-white rounded"}>
                <Form>
                    <FormGroup row className={"mb-3"}>
                        <Label for="exampleText" sm={2} className={"fw-bold"}>Từ khóa</Label>
                        <Col sm={10}>
                            <Input type="textarea" name="text" id="UncontrolledTooltipExample" value={keyword} onChange={(e) => setKeyword(e.target.value)} />
                            <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample" placement={"top"} >
                                Mỗi từ khóa nằm trên 1 dòng
                            </UncontrolledTooltip>
                        </Col>
                    </FormGroup>
                    <FormGroup row className={"mb-3"}>
                        <Label for="exampleText" sm={2} className={"fw-bold"}>Trang web tham khảo</Label>
                        <Col sm={10}>
                            <Input type="text" name="password" placeholder="example:example.com" value={base_link} onChange={(e) => setBaseLink(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Button color={"info"} className={"fw-bold"} style={{ color: "white" }} onClick={check_ads_key}>Kiểm tra</Button>
                    </FormGroup>
                </Form>
            </Row>
            {
                list_search.length > 0 && (
                    <Row className={"my-5 shadow p-3 mb-5 bg-white rounded"}>

                        <Table responsive>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Từ khóa</th>
                                    <th>Số lượng tìm kiếm trung bình tháng</th>
                                    <th>Giá từ khóa đầu trang (Top 1,2,3)</th>
                                    <th>Giá từ khóa đầu trang (Top 8,9,10)</th>
                                    <th>Cạnh tranh</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    list_search.map((item, index) => (
                                        <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{item.keyword}</td>
                                            <td>{item.avgmonthySearch}</td>
                                            <td>{makeVND(item.hightopBid)} VND</td>
                                            <td>{makeVND(item.lowtopBid)} VND</td>
                                            <td>{item.competition}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </Table>

                    </Row>
                )
            }
        </Container>
    )
}



export default (Planner)