import SearchEngine from "../Object/SearchEngine.object";
import en from "../image/flag/svg/186-united states.svg"
import no from "../image/flag/svg/205-norway.svg"
import it from "../image/flag/svg/263-italy.svg"
import bd from "../image/flag/svg/134-bangladesh.svg"
import my from "../image/flag/svg/207-malaysia.svg"
import nl from "../image/flag/svg/077-netherlands.svg"
import uk from "../image/flag/svg/110-united kingdom.svg"
import au from "../image/flag/svg/143-australia.svg"
import io from "../image/flag/svg/055-india.svg"
import de from "../image/flag/svg/208-germany.svg"
import fr from "../image/flag/svg/197-france.svg"
import ca from "../image/flag/svg/066-canada.svg"
import pk from "../image/flag/svg/232-pakistan.svg"
import ae from "../image/flag/svg/195-united arab emirates.svg"
import es from "../image/flag/svg/230-spain.svg"
import za from "../image/flag/svg/188-south africa.svg"
import nz from "../image/flag/svg/215-new zealand.svg"
import pl from "../image/flag/svg/165-poland.svg"
import ie from "../image/flag/svg/193-ireland.svg"
import qa from "../image/flag/svg/244-qatar.svg"
import ch from "../image/flag/svg/176-switzerland.svg"
import sa from "../image/flag/svg/204-saudi arabia.svg"
import ph from "../image/flag/svg/187-philippines.svg"
import id from "../image/flag/svg/185-indonesia.svg"
import lk from "../image/flag/svg/023-sri lanka.svg"
import ar from "../image/flag/svg/199-argentina.svg"
import br from "../image/flag/svg/022-brazil.svg"
import kw from "../image/flag/svg/084-kuwait.svg"
import tr from "../image/flag/svg/154-turkey.svg"
import sg from "../image/flag/svg/132-singapore.svg"
import np from "../image/flag/svg/012-nepal.svg"
import vn from "../image/flag/svg/109-vietnam.svg"

export const SearchEngines = [
    
    new SearchEngine(0,"United States","Google.com","en"),
    new SearchEngine(1,"Norway","Google.no","no"),
    new SearchEngine(2,"Italy","Google.it","it"),
    new SearchEngine(3,"Bangladesh","Google.com.bd","bd"),
    new SearchEngine(4,"Malaysia","Google.com.my","my"),
    new SearchEngine(5,"Netherlands","Google.nl","nl"),
    new SearchEngine(6,"United Kingdom","Google.co.uk","uk"),
    new SearchEngine(7,"Australia","Google.com.au","au"),
    new SearchEngine(8,"India","Google.co.in","io"),
    new SearchEngine(9,"Germany","Google.de","de"),
    new SearchEngine(10,"France","Google.fr","fr"),
    new SearchEngine(11,"Canada","Google.ca","ca"),
    new SearchEngine(12,"Pakistan","Google.com.pk","pk"),
    new SearchEngine(13,"United Arab Emirates","Google.ae","ae"),
    new SearchEngine(14,"Spain","Google.es","es"),
    new SearchEngine(15,"South Africa","Google.co.za","za"),
    new SearchEngine(16,"New Zealand","Google.co.nz","nz"),
    new SearchEngine(17,"Poland","Google.pl","pl"),
    new SearchEngine(18,"Ireland","Google.ie","ie"),
    new SearchEngine(19,"Qatar","Google.com.qa","qa"),
    new SearchEngine(20,"Switzerland","Google.ch","ch"),
    new SearchEngine(21,"Saudi Arabia","Google.com.sa","sa"),
    new SearchEngine(22,"Philippines","Google.com.ph","ph"),
    new SearchEngine(23,"Indonesia","Google.co.id","id"),
    new SearchEngine(24,"Sri Lanka","Google.lk","lk"),
    new SearchEngine(25,"Argentina","Google.com.ar","ar"),
    new SearchEngine(26,"Brazil","Google.com.br","br"),
    new SearchEngine(27,"Kuwait","Google.com.kw","kw"),
    new SearchEngine(28,"Turkey","Google.com.tr","tr"),
    new SearchEngine(29,"Singapore","Google.com.sg","sg"),
    new SearchEngine(30,"Nepal","Google.com.np","np"),
    new SearchEngine(31,"Việt Nam","Google.com.vn","vn"),
    
]
export const FLAG_IMAGES = [
    en,
    no,
    it,
    bd,
    my,
    nl,
    uk,
    au,
    io,
    de,
    fr,
    ca,
    pk,
    ae,
    es,
    za,
    nz,
    pl,
    ie,
    qa,
    ch,
    sa,
    ph,
    id,
    lk,
    ar,
    br,
    kw,
    tr,
    sg,
    np,
    vn
]