import {useState, useEffect} from "react"
import {Button, Container, Row, Form, FormGroup, Label, Input, Col, UncontrolledTooltip,} from "reactstrap";
import Project from "../../../Object/project.object";
import Keyword from "../../../Object/keyword.object";

const AddKeyword = (props) => {

    const [data, setData] = useState(props.data)
    const [project_keyword, setProjectKeyword] = useState("")

    useEffect(() => {
        setData(props.data)
        setProjectKeyword("")
    }, [props.data])

    const submit_add_keyword = () => {
        let keys = project_keyword.split("\n");
        keys = keys.filter((value) => value !== "");
        console.log(keys);
        keys = keys.filter((value, index, self) => self.indexOf(value) === index)

        const keylist = [];
        keys.forEach(key => {
            keylist.push(new Keyword("", key, null, null, null, null, null));
        })
        const update_keyword = new Project(data.id, data.project_name, data.check_link, data.create_time, keylist, data.owner);

        props.submit(update_keyword)
    }

    return (

        <Container fluid>
            <Row><Col><h3>Thêm từ khóa</h3></Col></Row>
            <Row>
                <Form>
                    <FormGroup row className={"mb-3"}>

                        <FormGroup row className={"mb-3"}>
                            <Label for="exampleText" sm={2}>Từ khóa</Label>
                            <Col sm={10}>
                                <Input type="textarea" name="text" id="UncontrolledTooltipExample"
                                       onChange={(e) => setProjectKeyword(e.target.value)} value={project_keyword}/>
                                <UncontrolledTooltip placement="right" target="UncontrolledTooltipExample"
                                                     placement={"top"}>
                                    Mỗi từ khóa nằm trên 1 dòng
                                </UncontrolledTooltip>
                            </Col>
                        </FormGroup>
                    </FormGroup>
                    <FormGroup>
                        <Button onClick={() => submit_add_keyword()} color={"success"}>Thêm từ khóa</Button>
                    </FormGroup>
                </Form>
            </Row>
        </Container>
    )

}
export default AddKeyword;