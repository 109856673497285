import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import * as spinnerTypes from "../../constants/Spinner.constant";
import * as methodTypes from "../../constants/method.constants";
import { ActionWrapper } from "../../Object/redux.object";
import Spinner from "../../Object/Spinner.object";
import User from "../../Object/User.object";
import HookAPI from "../../utility/HookAPI";
const SPINNER_BASE_PATH = "spinner";


const get_user = (state: any) => state.ApplicationRepository;

function* watchFetchSpinnerAsync(action: ActionWrapper) {
    const [result, error]: any[] = yield call(HookAPI, SPINNER_BASE_PATH + "/");
    if (result) {
        const spinners: Spinner[] = [];
        const data = result.data;
        yield data.forEach((item: any) => {


            let user = new User("", "", "", "");
            if (item.owner) {

                user = new User(item.owner.id, item.owner.name, item.owner.role, item.owner.email);
            }

            const spinner = new Spinner(item.id, item.name, item.type, item.data, user, item.createDate, item.modifiDate)
            spinners.push(spinner)
        })
        yield put({
            type: spinnerTypes.FETCH_SPINNER_ASYNC,
            data: spinners
        })
    }

}

function* watchCreateSpinnerAsync(action: ActionWrapper) {

    const data = action.data;
    let spinner = new Spinner("",data.name,null,data.data,new User("","","",""),"","");

    const [result, error]: any[] = yield call(HookAPI, SPINNER_BASE_PATH + "/", spinner, methodTypes.POST,true);
    if (result) {
        const item = result.data;
        let user = new User("", "", "", "");
        if (item.owner) {

            user = new User(item.owner.id, item.owner.name, item.owner.role, item.owner.email);
        }
        
        const spinner = new Spinner(item.id, item.name, item.type, item.data, user, item.createDate, item.modifiDate)
        yield put({
            type: spinnerTypes.CREATE_SPINNER_DATA_ASYNC,
            data: spinner
        })
    }

}

function* watchEditSpinnerAsync(action: ActionWrapper) {

    const data = action.data;
    let spinner = new Spinner(data.id,data.name,data.type,data.data,data.user,data.createDate,data.modifiDate);

    const [result, error]: any[] = yield call(HookAPI, SPINNER_BASE_PATH + "/"+spinner.id, spinner, methodTypes.POST,true);
    if (result) {
        const item = result.data;
        let user = new User("", "", "", "");
        if (item.owner) {

            user = new User(item.owner.id, item.owner.name, item.owner.role, item.owner.email);
        }
        
        const spinner = new Spinner(item.id, item.name, item.type, item.data, user, item.createDate, item.modifiDate)
        yield put({
            type: spinnerTypes.EDIT_SPINNER_DATA_ASYNC,
            data: spinner
        })
    }

}


function* watchRemoveSpinnerAsync(action: ActionWrapper) {

    const data = action.data;

    const [result, error]: any[] = yield call(HookAPI, SPINNER_BASE_PATH + "/"+data, null, methodTypes.DELETE,true);
    if (result) {
        yield put({
            type: spinnerTypes.REMOVE_SPINNER_DATA_ASYNC,
            data: data
        })
    }

}

function* watchFetchSpinner() {
    yield takeLatest(spinnerTypes.FETCH_SPINNER, watchFetchSpinnerAsync)
}

function* watchCreateSpinner() {
    yield takeLatest(spinnerTypes.CREATE_SPINNER_DATA, watchCreateSpinnerAsync)
}

function* watchEditSpinner() {
    yield takeLatest(spinnerTypes.EDIT_SPINNER_DATA, watchEditSpinnerAsync)
}

function* watchRemoveSpinner() {
    yield takeLatest(spinnerTypes.REMOVE_SPINNER_DATA, watchRemoveSpinnerAsync)
}


export default function* spinnerSaga() {
    yield all([
        fork(watchFetchSpinner),
        fork(watchCreateSpinner),
        fork(watchEditSpinner),
        fork(watchRemoveSpinner)
    ])
}