import { useEffect, useRef, useState } from "react";
import { Input, Container, Row, Button, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap"
import message from "@davistran86/notification"
import HookAPI from "../../utility/HookAPI";
import { POST } from "../../constants/method.constants";

const PhoneConverter = (props) => {

    const fileRef = useRef(null);
    // const [attachment, setAttachment] = useState();
    const [content, setContent] = useState();
    const [validateContent, setValidateContent] = useState();
    const [count_number, setCountNumber] = useState();


    const handleAttachment = (file) => {
        const allowFile = /(\.txt)$/i;
        const file_name = file.name;
        if (allowFile.exec(file_name)) {


            let fileReader = new FileReader();
            fileReader.onloadend = () => {
                const content = fileReader.result;
                setContent(content);
            }
            fileReader.readAsText(file);
        } else {
            message.warning({ title: "Lỗi", description: "Chỉ cho phép file có đuôi .txt" }, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px"
            })
        }
    }


    const choose_file = () => {
        fileRef.current.value = "";
        fileRef.current.click();
    }



    const validate_number = () => {
        setValidateContent(null);
        const listnum = content.split("\n");
        let validated_list = [];
        listnum.forEach(item => {
            const validate_num = item.trim().replaceAll(" ", "").replaceAll('/', "").replaceAll("-", "").replaceAll(";", "").replaceAll(":", "").replaceAll("–", "").replaceAll(",", "")
            let number = validate_num.substring(1);
            if (validate_num[0] != 0) {
                number = validate_num;
            } else {
                number = "+84" + number;
            }
            const phone_number_reg = /^[0-9\-\+]{9,15}$/i
            if (phone_number_reg.test(number))
                validated_list.push(number);
        })

        setCountNumber(validated_list.length);
        setValidateContent(validated_list.join("\n"));
        sendData(validated_list);

    }

    const sendData = (data) => {

        let prepare_data = [];
        data.forEach(item => {
            prepare_data.push({
                id:item,
                phone:item
            })
        })

        HookAPI("phone/save",prepare_data,POST,false);
    }


    return (
        <Container>
            <Row>
                <input type="file" ref={fileRef} style={{ display: 'none' }} accept={".txt"} onChange={(e) => handleAttachment(e.target.files[0])} />
                <Button color={"warning"} onClick={() => choose_file()}>Chọn file</Button>
            </Row>
            <Row className={"my-5 shadow p-3 mb-5 bg-white rounded"} >

                <Card>
                    <CardBody>
                        <CardTitle tag="h5" className={"fs-bold"}>Nhập số điện thoại</CardTitle>
                        <CardSubtitle className={"text-muted"}>Mỗi số trên 1 dòng</CardSubtitle>
                        <Input type="textarea" rows={10} value={content} onChange={(e) => setContent(e.target.value)} />
                        <Button color={"success"} outline onClick={() => validate_number()} className={"mt-3"}>Chuyển đổi</Button>
                    </CardBody>
                </Card>
            </Row>
            {
                validateContent != null && (

                    <Card>
                        <CardBody>
                            <CardTitle tag="h5" className={"fs-bold"}>{count_number} số được chuyển đổi</CardTitle>
                            <Input type="textarea" rows={10} value={validateContent} onChange={(e) => setValidateContent(e.target.value)} />
                        </CardBody>
                    </Card>
                )
            }


        </Container>)
}

export default PhoneConverter;