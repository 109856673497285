
import $ from 'jquery';

export const spinDataPreview = (obj) => {
    obj = $(obj);
    var html = "";
    var text = "";
    if (obj.is(":text")) {
        html = obj.val();
        text = html;
    } else {
        html = obj.html();
        html = html.replace(/&nbsp;/gi, ' ');
        obj.html(html);
        text = html;
    }
    var listSpin = GetTextSpin(text);
    $(listSpin).each(function (i, e) {
        if (e.length > 2) {
            var objText = e.trim().substring(1, e.length - 1);
            objText = SpinChildPreview(objText);
            var textArr = objText.split("|");
            var rand = Math.floor((Math.random() * textArr.length));
            var textRand = textArr[rand];
            if (isUrl(textRand)) {
                html = html.replace(e, textRand);
            } else {
                html = html.replace(e, "<span class=\"marker\">" + textRand + "</span>");
            }
        }
    })


    html = applySentenceCase(html);

    return html;
}

function applySentenceCase(s) {
    s = applySentenceCaseText(s);

    return s;
}

export const fillSpinData = (obj, dataSpin) => {
    var listWords = [];
    var listGroups = [];
    var listDataRow = dataSpin.split('\n');
    for (var i = 0; i < listDataRow.length; i++) {
        var r = listDataRow[i];
        listGroups.push({ Id: i, StringSpin: r });
    }

    for (var i = 0; i < listGroups.length; i++) {
        var g = listGroups[i];
        var stringSpin = g.StringSpin.substring(1, g.StringSpin.length - 1);
        var arrWords = stringSpin.split('|');
        for (var k = 0; k < arrWords.length; k++) {
            listWords.push({ Name: arrWords[k], WordGroup: g.Id });
        }
    }

    listWords.sort(function (a, b) {
        return b.Name.length - a.Name.length;
    })

    obj = $(obj);
    var html = "";
    var text = "";
    if (obj.is(":text")) {
        html = obj.val();
        text = html;
    } else {
        html = obj.html();
        text = obj.text();
    }
    text = convertToUnicode(text);
    html = convertToUnicode(html);
    html = html.split('&nbsp;').join(' ').split(' ').join(' ');
    text = text.split('&nbsp;').join(' ').split(' ').join(' ');
    var listSpin = GetTextSpin(text);
    $(listSpin).each(function (i, e) {
        html = html.split(e).join("#HSPIN" + i + "#");
    });
    obj.html(html);

    if (obj.is(":text")) {
        console.log("come here")
        for (var i = 0; i < listWords.length; i++) {
            var objWords = listWords[i];
            html = customReplaceAll(html, objWords.Name, "#GROUP" + objWords.WordGroup + "#");
        }
    } else {
        var listNode = textNodesUnder(obj.get(0));
        for (var k = 0; k < listNode.length; k++) {
            var nodeText = listNode[k].nodeValue;
            for (var i = 0; i < listWords.length; i++) {
                var objWords = listWords[i];
                nodeText = customReplaceAll(nodeText, objWords.Name, "#GROUP" + objWords.WordGroup + "#");
            }
            listNode[k].nodeValue = nodeText;
        }
    }

    if (!obj.is(":text")) {
        html = obj.html();
    }


    for (var i = 0; i < listGroups.length; i++) {
        var group = listGroups[i];
        html = html.split("#GROUP" + group.Id + "#").join(group.StringSpin);
    }

    $(listSpin).each(function (i, e) {
        html = html.split("#HSPIN" + i + "#").join(e);
    });

    return html
}



const customReplaceAll = (data, search, replace) => {
    if (data != "") {
        var firstSpace = data[0] == " ";
        var lastSpace = data[data.length - 1] == " ";
        data = " " + data.trim() + " ";
        var regex = new RegExp(" " + search + " ", "gi");
        var regex1 = new RegExp(" " + search + "\\.", "gi");
        var regex2 = new RegExp(" " + search + "\\,", "gi");
        data = data.replace(regex, " " + replace + " ");
        data = data.replace(regex1, " " + replace + ".");
        data = data.replace(regex2, " " + replace + ",");
        data = data.trim();
        if (firstSpace) {
            data = " " + data;
        }
        if (lastSpace) {
            data = data + " ";
        }
    }
    return data;
}

const customReplaceFirst = (data, search, replace) => {
    if (data != "") {
        var firstSpace = data[0] == " ";
        var lastSpace = data[data.length - 1] == " ";
        data = " " + data.trim() + " ";
        var regex = new RegExp(" " + search + " ", "i");
        var regex1 = new RegExp(" " + search + "\\.", "i");
        var regex2 = new RegExp(" " + search + "\\,", "i");
        data = data.replace(regex, " " + replace + " ");
        data = data.replace(regex1, " " + replace + ".");
        data = data.replace(regex2, " " + replace + ",");
        data = data.trim();
        if (firstSpace) {
            data = " " + data;
        }
        if (lastSpace) {
            data = data + " ";
        }
    }
    return data;
}


function GetTextSpin(text) {
    var result = new Array();
    var start = 0;
    var spinText = "";

    for (var i = 0; i < text.length; i++) {
        var charOne = text[i];
        if (charOne == '{') {
            start++;
        } else if (charOne == '}') {
            start--;
        }
        if (start >= 1) {
            spinText += charOne;
        } else if (start == 0 && charOne == '}') {
            result.push(spinText + "}");
            spinText = "";
        }
    }
    return result;
}

var unicodeCharacter = [
    "á", "à", "ả", "ã", "â", "ấ", "ầ", "ẩ", "ẫ", "ă", "ắ", "ằ", "ặ", "ẳ", "ẵ", "ậ", "ạ",
    "Á", "À", "Ả", "Ã", "Â", "Ấ", "Ầ", "Ẩ", "Ẫ", "Ă", "Ắ", "Ằ", "Ẳ", "Ẵ", "Ặ", "Ậ", "Ạ",
    "é", "è", "ẻ", "ẽ", "ê", "ế", "ề", "ể", "ễ", "ẹ", "ệ",
    "É", "È", "Ẻ", "Ẽ", "Ê", "Ế", "Ề", "Ể", "Ễ", "Ệ", "Ẹ",
    "ó", "ò", "ỏ", "õ", "ô", "ố", "ồ", "ộ", "ổ", "ỗ", "ơ", "ớ", "ờ", "ở", "ỡ", "ợ", "ọ",
    "Ó", "Ò", "Ỏ", "Õ", "Ô", "Ố", "Ồ", "Ổ", "Ỗ", "Ơ", "Ớ", "Ờ", "Ở", "Ỡ", "Ợ", "Ộ", "Ọ",
    "ú", "ù", "ủ", "ũ", "ư", "ứ", "ừ", "ử", "ữ", "ự", "ụ",
    "Ú", "Ù", "Ủ", "Ũ", "Ư", "Ứ", "Ừ", "Ử", "Ữ", "Ự", "Ụ",
    "í", "ì", "ỉ", "ĩ", "ị",
    "Í", "Ì", "Ỉ", "Ĩ", "Ị",
    "đ",
    "Đ",
    "ý", "ỳ", "ỷ", "ỹ", "ỵ",
    "Ý", "Ỳ", "Ỷ", "Ỹ", "Ỵ"
];

var unicodeCharacterToHop = [
    "á", "à", "ả", "ã", "â", "ấ", "ầ", "ẩ", "ẫ", "ă", "ắ", "ằ", "ặ", "ẳ", "ẵ", "ậ", "ạ",
    "Á", "À", "Ả", "Ã", "Â", "Ấ", "Ầ", "Ẩ", "Ẵ", "Ă", "Ắ", "Ằ", "Ă", "Ẵ", "Ặ", "Ậ", "Ạ",
    "é", "è", "ẻ", "ẽ", "ê", "ế", "ề", "ể", "ễ", "ẹ", "ệ",
    "É", "È", "Ẻ", "Ẽ", "Ê", "Ế", "Ề", "Ể", "Ễ", "Ệ", "Ẹ",
    "ó", "ò", "ỏ", "õ", "ô", "ố", "ồ", "ộ", "ổ", "ỗ", "ơ", "ớ", "ờ", "ở", "ỡ", "ợ", "ọ",
    "Ó", "Ò", "Ỏ", "Õ", "Ô", "Ố", "Ồ", "Ổ", "Ỗ", "Ơ", "Ớ", "Ờ", "Ở", "Ỡ", "Ợ", "Ộ", "Ọ",
    "ú", "ù", "ủ", "ũ", "ư", "ứ", "ừ", "ử", "ữ", "ự", "ụ",
    "Ú", "Ù", "Ủ", "Ũ", "Ư", "Ứ", "Ừ", "Ử", "Ữ", "Ự", "Ụ",
    "í", "ì", "ỉ", "ĩ", "ị",
    "Í", "Ì", "Ỉ", "Ĩ", "Ị",
    "đ",
    "Đ",
    "ý", "ỳ", "ỷ", "ỹ", "ỵ",
    "Ý", "Ỳ", "Ỷ", "Ỹ", "Ỵ"
];

const convertToUnicode = (str) => {
    for (var i = 0; i < unicodeCharacter.length; i++) {
        str = str.split(unicodeCharacterToHop[i]).join(unicodeCharacter[i]);
    }
    return str;
}

function textNodesUnder(node) {
    var all = [];
    for (node = node.firstChild; node; node = node.nextSibling) {
        if (node.nodeType == 3) all.push(node);
        else all = all.concat(textNodesUnder(node));
    }
    return all;
}


function SpinChildPreview(objText) {
    var listSpinChild = GetTextSpin(objText);
    if (listSpinChild.length > 0) {
        $(listSpinChild).each(function (i1, e1) {
            var objText1 = e1.trim().substring(1, e1.length - 1);
            objText1 = SpinChildPreview(objText1);
            var textArr1 = objText1.split("|");
            var rand1 = Math.floor((Math.random() * textArr1.length));
            objText = objText.replace(e1, "<span class=\"marker\">" + textArr1[rand1] + "</span>");
        })
    }
    return objText;
}


function isUrl(s) {
    var regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
    return regexp.test(s);
}


function applySentenceCaseText(s) {
    var IsNewSentense = true;
    var result = '';
    for (let i = 0; i < s.length; i++) {
        if (IsNewSentense && isLetter(s[i])) {
            result += s[i].toUpperCase();
            IsNewSentense = false;
        }
        else {
            result += s[i];
        }

        if ((s[i] == '!' || s[i] == '?' || s[i] == '.') && s[i + 1] == ' ') {
            IsNewSentense = true;
        }
    }
    return result;
}


function isLetter(character) {
    var flag = 0;
    if (character == character.toUpperCase()) {
        flag++;
    }
    if (character == character.toLowerCase()) {
        flag++;
    }
    return flag != 2;
}