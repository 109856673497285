import React, { useEffect, useState } from 'react'
import {
    ProSidebar,
    Menu,
    MenuItem,
    SubMenu,
    SidebarHeader,
    SidebarFooter,
    SidebarContent,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import sidebarBg from '../../image/background.jpg';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';
import * as applicationAction from "../../constants/application.constant"
import { useWindowSize } from '../../utility/ResizeHook';

const Sidebar = (props) => {
    const [application, setApplication] = useState(props.applicationConfig);

    const size = useWindowSize();
    useEffect(() => {
        let appl = props.applicationConfig;
        setApplication(appl);
    }, [props.applicationConfig])

    const handleToggleSidebar = (bol) => {
        let setting = props.applicationConfig;
        setting.toggle_sidebar = bol;
        props.updateApplication(setting)
    }
    const handleCollapseSidebar = (bol) => {
        let setting = props.applicationConfig;
        setting.collapse_sidebar = bol;
        props.updateApplication(setting)
    }
    return (
        <ProSidebar
            image={sidebarBg}
            collapsed={application.collapse_sidebar}
            toggled={application.toggle_sidebar}
            breakPoint="md"
            style={{ height: size.height }}
            onToggle={(e) => handleToggleSidebar(false)}
        >
            <SidebarHeader>
                <div
                    style={{
                        padding: '24px',
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        fontSize: 14,
                        letterSpacing: '1px',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        whiteSpace: 'nowrap',
                    }}
                >
                </div>
            </SidebarHeader>

            <SidebarContent>

                {
                    props.sidebarMenu.map(element =>
                    (
                        <Menu iconShape="circle" key={element.id}>
                            {
                                element.sub_menu ? (
                                    <SubMenu prefix={<span className="badge yellow">{element.name}</span>} icon={element.icon}>
                                        {
                                            element.sub_menu.map(el =>
                                                (el.role) && (
                                                    <MenuItem icon={el.icon} key={el.id}><Link to={el.path} onClick={() => { if ((typeof element.action) === "function") { element.action() } }}>{el.name}</Link></MenuItem>
                                                )
                                            )
                                        }
                                    </SubMenu>
                                ) : (
                                    <MenuItem icon={element.icon}>
                                        {
                                            element.isOutPath ?
                                                <a href={element.path}>{element.name}</a>
                                                :
                                                <Link to={element.path} onClick={() => { if ((typeof element.action) === "function") { element.action() } }}>{element.name}</Link>
                                        }

                                    </MenuItem>
                                )
                            }
                        </Menu>
                    )
                    )
                }
            </SidebarContent>

            <SidebarFooter style={{ textAlign: 'center' }}>
                <div className="sidebar-btn-wrapper" style={{ padding: '20px 24px', }}>

                    {
                        application.collapse_sidebar && <FaChevronRight size={20} style={{ cursor: "pointer" }} onClick={() => handleCollapseSidebar(false)} />
                    }
                    {
                        !application.collapse_sidebar && <FaChevronLeft size={20} style={{ cursor: "pointer" }} onClick={() => handleCollapseSidebar(true)} />
                    }
                </div>
            </SidebarFooter>
        </ProSidebar>
    )
}

const mapStateToProps = state => ({
    applicationConfig: state.ApplicationRepository.app_setting
})

const mapDispatchToProps = {
    updateApplication: (value) => ({
        type: applicationAction.TOGGLE_SIDEBAR,
        data: value
    }),
};


export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)