import Project from "../../Object/project.object";
import {ActionWrapper} from "../../Object/redux.object";
import User from "../../Object/User.object";
import * as project_type from "../../constants/project.constant";


const ProjectRepository = (state: Project[] = [], action: ActionWrapper) => {


    const edit_project = (data: Project) => {

        const index = state.findIndex(item => item.id === data.id);

        state[index] = {...data};

        return state;

    }
    const checked_keyword = (data: any) => {
        const index = state.findIndex(item => item.id === data.id);

        state[index].keywords = [...data.keywords]
        return state;

    }

    const remove_project = (data: String) => {
        const index = state.findIndex(item => item.id === data);

        state.splice(index, 1)
        return state;

    }

    const filter_state = (a: Project[]) => {

        let arr: Project[] = []
        a.forEach((item) => {

            if (arr.filter((i) => i.id == item.id).length == 0) {
                arr.push(item)
            }
        })
        return [...arr];
    }

    switch (action.type) {
        case project_type.FETCH_PROJECTS_ASYNC:


            state.push(...action.data);
            state = filter_state(state);
            return [...state];
        case project_type.CREATE_PROJECT_ASYNC:
            state.push(action.data)
            state = filter_state(state);

            return [...state]
        case project_type.EDIT_PROJECT_ASYNC:

            state = edit_project(action.data);
            return [...state]
        case project_type.EDIT_RANK_KEYWORD_ASYNC:

            const projectIndex = state.findIndex(item => item.id === action.data.projectId)
            const keywordIndex = state[projectIndex].keywords.findIndex(item => item.id === action.data.id)
            state[projectIndex].keywords[keywordIndex] = action.data;
            return [...state];
        case project_type.CHECK_KEY_PROJECT_ASYNC:
            const data = action.data;
            checked_keyword(data)

            return [...state];
        case project_type.REMOVE_PROJECT_ASYNC:

            state = remove_project(action.data);

            return [...state];
        default:
            state = filter_state(state);
            return state;
    }
}

export default ProjectRepository;