import { useEffect, useState } from "react";
import Moment from "react-moment";
import { connect } from "react-redux";
import { Button, Col, Container, Input, Label, Row } from "reactstrap";
import { CSSTransition } from 'react-transition-group';
import * as projectTypes from "../../../constants/project.constant";

import "./index.css"

const Notes = ({ ...props }) => {

    const [data, setData] = useState(props.keyword)
    const [isAdd, setAdd] = useState(false)
    const [note, setNote] = useState("");


    useEffect(() => {

        const findedKey = props.project_data.keywords.find(item => item.id === data.id);
        setData(findedKey);

    }, [props.project_data])

    const style_container_note = {
        height: "180px",
        overflow: "hidden auto",
        boxShadow: "0 -5px 3px -3px rgba(0,0,0,0.05), 0 5px 3px -3px rgba(0,0,0,0.05)"
    }

    const onSaveNote = () => {

        let prepareObject = {
            project: props.project_data.id,
            key_id: data.id,
            note: note
        }
        props.add_new_note(prepareObject);
    }


    const onEditNote = (item) => {

        let prepareObject = {
            project: props.project_data.id,
            key_id: data.id,
            note: item.note,
            id:item.id
        }
        props.edit_note(prepareObject);
    }
    const onRemoveNote = (id) => {

        let prepareObject = {
            project: props.project_data.id,
            key_id: data.id,
            list: [id]
        }
        if (window.confirm("Delete the item?")) {
            props.delete_note(prepareObject);
        }

    }

    return (

        <Container fluid className={"shadow-lg p-3 mb-5 bg-white rounded"}>
            {
                !isAdd && (

                    <Row className={"text-center"}>
                        <Col>
                            <Button color={"success"} outline size={"sm"} onClick={() => setAdd(!isAdd)}>Thêm ghi chú</Button>
                        </Col>
                    </Row>
                )
            }


            <CSSTransition in={isAdd} timeout={100} classNames={"add-note"}
                unmountOnExit
                onEnter={() => setAdd(true)}
                onExited={() => setAdd(false)}
            >
                <div className="row ">
                    <div className="col add-item-container">
                        <div className="note-add-wrapper">
                            <label className="note-label-wrapper-add">
                                <Input type="textarea" value={note} onChange={(e) => setNote(e.target.value)} />
                            </label>
                            <div className="item-action">
                                <button onClick={() => setAdd(!isAdd)}>Cancel</button>
                                <button onClick={() => onSaveNote()}>Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </CSSTransition>
            <div style={style_container_note} className={"mt-3"}>
                <Row className={"my-3"} className={"note-item-container"} >
                    {
                        data.notes.sort((a, b) => b.modified_time - a.modified_time).map((item, index) => (
                            <NoteItem data={item} key={item.id} onEdit={onEditNote} onDelete={onRemoveNote} />
                        ))
                    }
                </Row></div>

        </Container >

    )
}

const NoteItem = (props) => {

    const [isEdit, setEdit] = useState(false);
    const [value, setValue] = useState(props.data.note);

    const text_style = {
        color: "blue",
        cursor: "pointer",
    }
    const submit_button_style = {
        backgroundColor: "rgb(24, 119, 242)",
        color: "white",
        opacity: 0.5
    }
    const submit_button_style_active = {
        backgroundColor: "rgb(24, 119, 242)",
        color: "white",
        opacity: 1
    }

    return (

        <div>
            <Container>
                {
                    !isEdit ? (
                        <div className="note-item-wrapper">
                            <div className="note-header">
                                <Moment fromNow>{props.data.modified_time}</Moment>
                                <span aria-hidden="true"> · </span>
                                <a onClick={() => setEdit(!isEdit)} style={text_style}>Edit</a>
                                <span aria-hidden="true"> · </span>
                                <a style={text_style} onClick={() => props.onDelete(props.data.id)}>Delete</a>
                            </div>
                            <div className="note-body">
                                <p><span>{props.data.note}</span></p>
                            </div>
                        </div>
                    ) : (
                        <div className="note-edit-wrapper " style={{ marginTop: 5 }}>
                            <label className="note-label-wrapper">
                                <Input type="textarea" value={value} onChange={(e) => setValue(e.target.value)} />
                            </label>
                            <div className="item-action">
                                <button onClick={() => setEdit(!isEdit)}>Cancel</button>
                                <button onClick={() => props.onDelete(props.data.id)}>Delete</button>
                                <button disabled={value == props.data.note} style={value == props.data.note ? submit_button_style : submit_button_style_active} onClick={() => props.onEdit({ id: props.data.id, note: value })}>Save</button>
                            </div>
                        </div>
                    )
                }
            </Container>
        </div >

    )
}

const mapStateToProps = (state) => ({
    project: state.ProjectRepository
})
const mapDispatchToProps = {
    fetchProject: () => ({
        type: projectTypes.FETCH_PROJECTS,
        data: null
    }),
    add_new_note: (data) => ({
        type: projectTypes.ADD_NEW_NOTE,
        data: data
    }),
    edit_note: (data) => ({
        type: projectTypes.EDIT_NOTE,
        data: data
    }),
    delete_note: (data) => ({
        type: projectTypes.DELETE_NOTE,
        data: data
    })
};

export default connect(mapStateToProps, mapDispatchToProps)(Notes);