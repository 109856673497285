import { useEffect, useState } from "react"
import { connect } from "react-redux";
import { Button, Container, Row, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Form, FormGroup, Label, Input, Col } from "reactstrap";
import * as spinnerTypes from "../../../constants/Spinner.constant"
import Spinner from "../../../Object/Spinner.object";
import message from "@davistran86/notification"

const SpinManager = (props) => {

    const [spinners, setSpinners] = useState([])
    const [selectedSpinData, setSelectedSpinData] = useState(null)
    const [dropdown, setDropdown] = useState(false);
    const [name, setName] = useState("");
    const [data, setData] = useState("");

    useEffect(() => {

        if (selectedSpinData != null && selectedSpinData != undefined) {
            setName(selectedSpinData.name)
            setData(selectedSpinData.data)
        }

    }, [selectedSpinData])
    useEffect(() => {
        setSpinners([...props.spinners])
    }, [props.spinners])

    const project_dropdown_toggle = () => setDropdown(!dropdown);

    const edit_data = () => {
        let object = { ...selectedSpinData };
        object.name = name;
        object.data = data;
        props.editSpinData(object);
    }
    const create_data = () => {

        let prject_by_name = spinners.find(item => item.name === name)
        if (prject_by_name != null) {

            message.warning({ title: "Thông báo", description: "Tên không được trùng với tên dự án đã có" }, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px"
            })
            return;
        }
        if (name == "") {
            message.warning({ title: "Thông báo", description: "Tên không được để trống" }, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px"
            })
            return;
        }
        let createdata = data;
        if (data == "") {

            createdata = selectedSpinData.data;

        }

        const update_data = new Spinner("", name, null, createdata, "", "", "");
        props.createSpinData(update_data)
    }
    const remove_data = (id) => {
        if (window.confirm("Bạn có chắc muốn xóa bộ dữ liệu này")) {
            props.removeSpinData(id)
        }
    }

    return (

        <Container fluid>
            <Row>

                <Dropdown isOpen={dropdown} toggle={project_dropdown_toggle} direction="right">
                    <DropdownToggle caret color={"success"}>
                        Chọn dữ liệu
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>Dữ liệu</DropdownItem>
                        {
                            spinners.map((item, index) => (

                                <DropdownItem value={item.id} key={item.id} onClick={() => setSelectedSpinData(item)}>{item.name ? item.name : `Dự án ${index + 1}`}</DropdownItem>
                            ))
                        }
                        <DropdownItem divider />
                    </DropdownMenu>
                </Dropdown>
            </Row>
            {
                selectedSpinData && (
                    <Container fluid  >
                        <Form>
                            <FormGroup row className={"mb-3"}>
                                <Label for="example_name" sm={4}>Tên dữ liệu</Label>
                                <Col sm={8}>
                                    <Input type="text" name="example_name" placeholder="Tên dữ liệu..." value={name} onChange={(e) => setName(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <FormGroup row className={"mb-3"}>
                                <Label for="example_link" sm={4}>Dữ liệu</Label>
                                <Col sm={8}>
                                    <Input style={{ height: 500 }} type="textarea" name="example_link" placeholder="example: {từ đồng nghĩa 1|từ đồng nghĩa 2|từ đồng nghĩa 3|...}" value={data} onChange={(e) => setData(e.target.value)} />
                                </Col>
                            </FormGroup>
                            <FormGroup>
                                <Row>
                                    <Col>
                                        <Button onClick={() => edit_data()} color={"info"} outline>Lưu dữ liệu</Button>
                                    </Col>
                                    <Col className={"text-end"}>
                                        <Button onClick={() => create_data()} color={"success"} outline>Tạo mới dữ liệu</Button>
                                    </Col>
                                    <Col className={"text-end"}>
                                        <Button onClick={() => remove_data(selectedSpinData.id)} color={"danger"} outline>Xóa</Button>
                                    </Col>
                                </Row>
                            </FormGroup>
                        </Form>
                    </Container>
                )
            }
        </Container>
    )


}

const mapStateToProps = (state) => ({
    spinners: state.SpinnerRepository
})

const mapDispatchToProps = {

    fetchSpinData: () => ({
        type: spinnerTypes.FETCH_SPINNER,
        data: null
    }),
    createSpinData: (data) => ({
        type: spinnerTypes.CREATE_SPINNER_DATA,
        data: data
    }),
    editSpinData: (data) => ({
        type: spinnerTypes.EDIT_SPINNER_DATA,
        data: data
    }),
    removeSpinData: (data) => ({
        type: spinnerTypes.REMOVE_SPINNER_DATA,
        data: data
    })
}

export default connect(mapStateToProps, mapDispatchToProps)(SpinManager)