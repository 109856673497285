import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import { APIMapper } from "../../constants/APIMapper";
import * as applicationTypes from "../../constants/application.constant"
import Application from "../../Object/application.object";
import { ActionWrapper } from "../../Object/redux.object";
import HookAPI from "../../utility/HookAPI";

function* watchLoginAsync(action: ActionWrapper) {


    let applicationObject = new Application("", "", "", "");
    const [result, error]: any[] = yield call(HookAPI, `${APIMapper.FACEBOOK_LOGIN}${action.data}`);

    if (result) {
        const data = result.data;
        applicationObject = new Application(data.id, data.facebook_user_name, data.token, data.expired_date);

        yield put({ type: applicationTypes.LOGIN_ASYNC, data: applicationObject })
    }


    else if (error) {
    }


}

function* watchLogoutAsync(action: ActionWrapper) {
    yield put({ type: applicationTypes.LOGOUT_ASYNC, data: action.data })

}



function* watchLogin() {
    yield takeLatest(applicationTypes.LOGIN, watchLoginAsync)
}

function* watchLogout() {
    yield takeLatest(applicationTypes.LOGOUT, watchLogoutAsync)
}


export default function* applicationSaga() {
    yield all([
        fork(watchLogin),
        fork(watchLogout)
    ])
}