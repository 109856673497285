interface IKeywordUpdate {
    id: string;
    keyword: string;
    currentRank: Number;
    oldRank: Number;
    checkedLink: string;
    timeCreate: Number;
    timeCheck: Number;
    volume: Number;
}


export default class KeywordUpdate implements IKeywordUpdate {
    public id: string;
    public keyword: string;
    public currentRank: Number;
    public oldRank: Number;
    public checkedLink: string;
    public timeCreate: Number;
    public timeCheck: Number;
    public volume: Number;

    constructor(
        id: string,
        keyword: string,
        currentRank: Number,
        oldRank: Number,
        checkedLink: string,
        timeCreate: Number,
        timeCheck: Number,
        volume: Number) {
        this.id = id;
        this.keyword = keyword;
        this.currentRank = currentRank;
        this.oldRank = oldRank;
        this.checkedLink = checkedLink;
        this.timeCreate = timeCreate;
        this.timeCheck = timeCheck;
        this.volume = volume;
    }
}