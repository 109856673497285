export const regex_domain = (string) => {
    let regex = /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/g

    let match = regex.exec(string);
    if (match != null) {
        let newString = match[0]

        if (newString.length !== 0 && match.index < 15) {
            return newString;
        }
    }
    else {
        return false;
    }
}