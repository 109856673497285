import { eventChannel } from "redux-saga";
import { call, fork, put, take } from "redux-saga/effects";
import * as check_type from "../../constants/checkrankstatus.constant"
import $ from 'jquery';
import { EDIT_RANK_KEYWORD } from "../../constants/project.constant";
import { regex_domain } from "../../utility/check_rank";

function* subscribe() {
    return eventChannel(emit => {
        document.addEventListener("add_on_checked", () => {
            emit({ type: check_type.DETECT_ADDON_SUCCESS, data: null })
        })
        document.addEventListener("captcha_checked", (data) => {
            emit({ type: check_type.CAPTCHA_SUCCESS, data: null })
        })
        document.addEventListener("captcha_checking", (data) => {
            emit({ type: check_type.CHECKING, data: null })
        })
        document.addEventListener("captcha_error", (data) => {
            emit({ type: check_type.CAPTCHA_ERROR, data: null })
        })
        document.addEventListener("captcha_success", () => {
            emit({ type: check_type.CAPTCHA_SUCCESS, data: null })
        })
        document.addEventListener("receive_content_success", (res) => {

            const detail = res.detail;

            const data = detail.html;
            
            const project = detail.project;
            const key_id = detail.id;

            var listLink = null;
            listLink = $(".g .yuRUbf div a", data);
            if (listLink.length == 0) {
                listLink = $("#rso .P8ujBc div a", data);
            }
            const link_details = [];
            listLink.each((index, item) => {
                link_details.push($(item)[0].href)
            })
            var check_link = project.check_link;

            check_link = regex_domain(check_link);
            check_link = check_link.toLowerCase();
            check_link = check_link.replace("www.", "");

            let isHavePosition = false;
            link_details.forEach((item, i) => {

                let link = item.toLowerCase();

                let link_finded = regex_domain(link);
                link_finded = link_finded.replace("www.", "");


                if (link_finded.startsWith(check_link) && isHavePosition !== true) {
                    isHavePosition = true;
                    emit({
                        type: EDIT_RANK_KEYWORD,
                        data: {
                            id: key_id,
                            curren_rank: i + 1,
                            project_id: project.id,
                            check_link: item
                        }
                    })
                    return true;
                }
            })
            if (!isHavePosition) {
                emit({
                    type: EDIT_RANK_KEYWORD,
                    data: {
                        id: key_id,
                        curren_rank: null,
                        project_id: project.id,
                        check_link: null
                    }
                })

                emit({
                    type: check_type.CONTINOUS_CHECK_RANK,
                    data: null
                })
            }
        })
        document.addEventListener("error_check_search_engine", () => {
            emit({ type: check_type.DETECT_ADDON_CAN_NOT_CHECK, data: null })
        })

        return () => { };
    })
}


function* read() {
    const channel = yield call(subscribe);
    while (true) {
        let action = yield take(channel);
        yield put(action);
    }
}

export function* documentEvent() {
    yield fork(read);
}
