export const FETCH_PROJECTS = "FETCH_PROJECTS";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const EDIT_PROJECT = "EDIT_PROJECT";
export const ADD_KEYWORD = "ADD_KEYWORD";
export const CHECK_KEY_PROJECT = "CHECK_KEY_PROJECT";
export const REMOVE_PROJECT = "REMOVE_PROJECT";
export const REMOVE_KEYWORD = "REMOVE_KEYWORD";

export const FETCH_PROJECTS_ASYNC = "FETCH_PROJECTS_ASYNC";
export const CREATE_PROJECT_ASYNC = "CREATE_PROJECT_ASYNC";
export const EDIT_PROJECT_ASYNC = "EDIT_PROJECT_ASYNC";
// export const ADD_KEYWORD_ASYNC = "ADD_KEYWORD_ASYNC";
export const CHECK_KEY_PROJECT_ASYNC = "CHECK_KEY_PROJECT_ASYNC";
export const REMOVE_PROJECT_ASYNC = "REMOVE_PROJECT_ASYNC";
export const REMOVE_KEYWORD_ASYNC = "REMOVE_KEYWORD_ASYNC";
export const EDIT_RANK_KEYWORD = "EDIT_RANK_KEYWORD"
export const EDIT_RANK_KEYWORD_ASYNC = "EDIT_RANK_KEYWORD_ASYNC"

// note

export const ADD_NEW_NOTE = "ADD_NEW_NOTE"
export const EDIT_NOTE = "EDIT_NOTE"
export const DELETE_NOTE = "DELETE_NOTE"
