interface IAppSetting {

    collapse_sidebar:Boolean;
    toggle_sidebar:Boolean;
    check_rank_status:String;

}

export default class AppSetting implements IAppSetting {
    public collapse_sidebar:Boolean;
    public toggle_sidebar:Boolean;
    public check_rank_status:String;

    constructor(
        collapse_sidebar:Boolean,
        toggle_sidebar:Boolean,
        check_rank_status:String) {
        this.collapse_sidebar = collapse_sidebar;
        this.toggle_sidebar = toggle_sidebar;
        this.check_rank_status =check_rank_status;
    }
}