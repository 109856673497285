import Application from "../../Object/application.object";
import { ActionWrapper } from "../../Object/redux.object";
import * as application_type from "../../constants/application.constant";
import Cookies from "js-cookie";
import AppSetting from "../../Object/appSetting.object";
const utility = require("../../config/utility.json");

const initialState = () => {

    const user_id = Cookies.get("_uid") ? Cookies.get("_uid") : "";
    const token = Cookies.get("_tkl");


    return new Application(user_id ? user_id : "", "", token ? token : "", "");
}

const login = (application: Application) => {
    Cookies.set("_uid", application.user_id, {
        expires: new Date(application.expired_date)
    })
    Cookies.set("_tkl", application.token, {
        expires: new Date(application.expired_date)
    })
    window.location.href = utility.BASE_WEB;

}


const logout = () => {
    Cookies.remove("_uid");
    Cookies.remove("_tkl");
    window.location.href = utility.BASE_WEB;
}

const ApplicationRepository = (state = initialState(), action: ActionWrapper) => {


    switch (action.type) {

        case application_type.FETCH_ASYNC:
            state = action.data
            return { ...state };
        case application_type.LOGIN_ASYNC:
            state = action.data;
            login(state);
            return { ...state };
        case application_type.LOGOUT_ASYNC:
            logout();
            return { ...state };
        case application_type.TOGGLE_CHECK_RANK_OVERLAY_ASYNC:

            let object: AppSetting = state.app_setting;
            object.check_rank_status = action.data;

            state.app_setting = { ...object };
            return { ...state }

        default:
            return { ...state };
    }
}

export default ApplicationRepository;