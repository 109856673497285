import './App.css';
import Login from './page/login';
import Home from "./page/Home";
import {
  BrowserRouter as Router,
  Route, Switch
} from "react-router-dom";
import { connect } from 'react-redux';
import { FaHome, FaChartBar, FaPencilRuler, FaSpinner, FaSignOutAlt, FaSortNumericUp, FaPhoneAlt } from 'react-icons/fa';
import Sidebar from './page/Sidebar';
import Main from './page/Main';
import * as applicationAction from "./constants/application.constant"
import ranking from './page/Keyword/ranking';
import Planner from './page/Keyword/planner';
import SpinnerText from "./page/Spin";
import PhoneConverter from './page/PhoneConverter';



const App = (props) => {


  const components = [
    {
      id: 1,
      path: "/",
      component: Home,
      exact: true,
      name: "Main",
      icon: <FaHome />,
      role: 1
    },
    {
      id: 2,
      path: "/ranking",
      component: ranking,
      exact: true,
      name: "Check Ranking",
      icon: <FaSortNumericUp />,
      role: 1
    },
    {
      id: 3,
      path: "/planner",
      component: Planner,
      exact: true,
      name: "Keyword Planner",
      icon: <FaPencilRuler />,
      role: 1
    },
    {
      id: 4,
      path: "/spin",
      component: SpinnerText,
      exact: true,
      name: "Spin Editor",
      icon: <FaSpinner />,
      role: 1
    },
    {
      id: 8,
      path: "https://500kuser.com",
      component: null,
      isOutPath: true,
      name: "Traffic user",
      icon: <FaChartBar />,
      role: 1
    },
    {
      id: 6,
      path: "https://faceseo.vn",
      component: null,
      isOutPath: true,
      name: "Tool tăng traffic",
      icon: <FaChartBar />,
      role: 1
    },
    {
      id: 7,
      path: "/phoneformat",
      sub: "Tạo tệp số điện thoại chuẩn để chạy FB Ads",
      component: PhoneConverter,
      isOutPath: true,
      name: "Format Phone FB",
      icon: <FaPhoneAlt />,
      role: 1
    },
    {
      id: 5,
      path: "#",
      component: null,
      action: props.logout,
      exact: true,
      name: "Logout",
      icon: <FaSignOutAlt />,
      role: 1
    }
  ]



  const updateApplicationConfig = (e) => {
    let applications = props.application.app_setting;
    applications.toggle_sidebar = e;
    props.updateApplication(applications);
  }

  const listComponent = components;
  if (props.application.oauth) {
    return (
      <div className="wrapper d-flex align-items-stretch">
        <Router >
          <div style={{ height: window.innerHeight }}>
            <Sidebar sidebarMenu={listComponent} />
          </div>
          <div id="content" className="p-4 p-md-5 bg-light overflow-auto" style={{ height: window.innerHeight }}>

            <div className="btn-toggle" onClick={(e) => updateApplicationConfig(true)}>
              <svg stroke="currentColor" fill="currentColor" strokeWidth={0} viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" /></svg>
            </div>
            <div className="row">
              <Switch>
                {
                  listComponent.map(({ path, component, exact }, key) => (
                    <Route
                      exact={exact}
                      path={path}
                      key={key}
                      render={props => {
                        const crumbs = components
                          .filter(({ path }) => props.match.path.includes(path))
                          .map(({ path, ...rest }) => ({
                            path: Object.keys(props.match.params).length
                              ? Object.keys(props.match.params).reduce(
                                (path, param) => path.replace(
                                  `:${param}`, props.match.params[param]
                                ), path
                              )
                              : path,
                            ...rest
                          }));
                        return (
                          <Main {...props} crumbs={crumbs} Component={component} />
                        );
                      }}
                    />
                  ))
                }
              </Switch>
            </div>


          </div>
        </Router>



      </div>
    );
  } else {
    return (
      <Router>
        <Route path={"/*"} component={Login} />
      </Router>
    )
  }


}

const mapStateToProps = state => ({
  application: state.ApplicationRepository
})


const mapDispatchToProps = {
  updateApplication: (value) => ({
    type: applicationAction.TOGGLE_SIDEBAR,
    data: value
  }),
  logout: () => ({
    type: applicationAction.LOGOUT,
    data: null
  })
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
