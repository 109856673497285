export const FETCH = "FETCH"
export const LOGIN = "LOGIN"
export const LOGOUT ="LOGOUT"
export const TOGGLE_SIDEBAR = "TOGGLE_SIDEBAR"
export const FETCH_ASYNC = "FETCH_ASYNC"
export const LOGIN_ASYNC = "LOGIN_ASYNC"
export const LOGOUT_ASYNC ="LOGOUT_ASYNC"
export const TOGGLE_SIDEBAR_ASYNC = "TOGGLE_SIDEBAR_ASYNC"
export const TOGGLE_CHECK_RANK_OVERLAY_ASYNC = "TOGGLE_CHECK_RANK_OVERLAY_ASYNC"
export const CHECK_RANK_KEYWORD = "CHECK_RANK_KEYWORD"
export const CHECK_RANK_KEYWORD_ASYNC = "CHECK_RANK_KEYWORD_ASYNC"
