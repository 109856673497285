import {all, call, fork, put, select, takeLatest} from "redux-saga/effects";
import {ActionWrapper} from "../../Object/redux.object";
import * as projectTypes from "../../constants/project.constant";
import HookAPI from "../../utility/HookAPI";
import Project from "../../Object/project.object";
import Keyword from "../../Object/keyword.object";
import User from "../../Object/User.object";
import * as methodTypes from "../../constants/method.constants";
import * as applicationTypes from "../../constants/application.constant";
import * as checkStatusTypes from "../../constants/checkrankstatus.constant";
import Application from "../../Object/application.object";
import KeywordUpdate from "../../Object/keyword_update.object";

const RANKING_PATH_BASE = "ranking";

const get_user = (state: any) => state.ApplicationRepository;
const get_projects = (state: any) => state.ProjectRepository;


function* watchFetchProjectAsync(action: ActionWrapper) {
    const [result, error]: any[] = yield call(HookAPI, RANKING_PATH_BASE + "/");
    if (result) {
        const projects: Project[] = [];
        const data = result.data;
        yield data.forEach((item: any) => {
            let keywords: Keyword[] = [];
            item.keywords.forEach((keyword: any) => {
                keywords.push(new Keyword(keyword.id, keyword.keyword, keyword.currentRank, keyword.oldRank, keyword.bestRank, keyword.checkedLink, keyword.timeCreate, keyword.timeCheck, keyword.chart, keyword.notes, keyword.volume));
            })
            let user = new User("", "", "", "");
            if (item.owner) {

                user = new User(item.owner.id, item.owner.name, item.owner.role, item.owner.email);
            }
            const project = new Project(item.id, item.project_name, item.check_link, item.create_time, keywords, user);
            projects.push(project);
        })
        yield put({
            type: projectTypes.FETCH_PROJECTS_ASYNC,
            data: projects
        })
    }


}

function* watchUpdateRankKeywordAsync(action: ActionWrapper) {

    const project_id = action.data.project_id;

    const keyword = new KeywordUpdate(action.data.id, "", action.data.curren_rank, -1, action.data.check_link, -1, -1, action.data.volume);

    const [result, error]: any[] = yield call(HookAPI, `${RANKING_PATH_BASE}/${project_id}/edit_key`, keyword, methodTypes.POST, false);
    if (result) {
        const item = result.data;


        let keywords: Keyword[] = [];

        let keywordOut = null;

        item.keywords.forEach((keyword: any) => {
            if(keyword.id === action.data.id){
                keywordOut = new Keyword(keyword.id, keyword.keyword, keyword.currentRank, keyword.oldRank, keyword.bestRank, keyword.checkedLink, keyword.timeCreate, keyword.timeCheck, keyword.chart, keyword.notes, keyword.volume, project_id);
            }
        })
        let user = new User("", "", "", "");
        if (item.owner) {

            user = new User(item.owner.id, item.owner.name, item.owner.role, item.owner.email);
        }
        const project = new Project(item.id, item.project_name, item.check_link, item.create_time, keywords, user);
        // yield put({
        //     type: projectTypes.EDIT_PROJECT_ASYNC,
        //     data: project
        // })
        yield put({
            type: projectTypes.EDIT_RANK_KEYWORD_ASYNC,
            data: keywordOut
        })
        yield put({
            type: checkStatusTypes.CONTINOUS_CHECK_RANK,
            data: null
        })
    }
}

function* watchCreateProjectAsync(action: ActionWrapper) {


    const app: Application = yield select(get_user);


    const curr_data: Project = yield action.data;

    let project = {
        id: curr_data.id,
        project_name: curr_data.project_name,
        check_link: curr_data.check_link,
        create_time: curr_data.create_time,
        keywords: curr_data.keywords,
        owner_id: app.user_id
    }

    const [result, error]: any[] = yield call(HookAPI, RANKING_PATH_BASE + "/", project, methodTypes.POST, false);

    if (result) {
        const item = result.data;


        let keywords: Keyword[] = [];
        item.keywords.forEach((keyword: any) => {
            keywords.push(new Keyword(keyword.id, keyword.keyword, keyword.currentRank, keyword.oldRank, keyword.bestRank, keyword.checkedLink, keyword.timeCreate, keyword.timeCheck, keyword.chart, keyword.notes, keyword.volume));
        })
        let user = new User("", "", "", "");
        if (item.owner) {

            user = new User(item.owner.id, item.owner.name, item.owner.role, item.owner.email);
        }
        const project = new Project(item.id, item.project_name, item.check_link, item.create_time, keywords, user);
        yield put({
            type: projectTypes.CREATE_PROJECT_ASYNC,
            data: project
        })
    }

}


function* watchEditProjectAsync(action: ActionWrapper) {


    const curr_data: Project = yield action.data;

    let send_project = {
        id: curr_data.id,
        project_name: curr_data.project_name,
        check_link: curr_data.check_link,
        create_time: curr_data.create_time,
        keywords: curr_data.keywords,
        owner_id: curr_data.owner.id
    }

    const [result, error]: any[] = yield call(HookAPI, `${RANKING_PATH_BASE}/${send_project.id}`, send_project, methodTypes.POST, true);

    if (result) {
        const item = result.data;


        let keywords: Keyword[] = [];
        item.keywords.forEach((keyword: any) => {
            keywords.push(new Keyword(keyword.id, keyword.keyword, keyword.currentRank, keyword.oldRank, keyword.bestRank, keyword.checkedLink, keyword.timeCreate, keyword.timeCheck, keyword.chart, keyword.notes, keyword.volume));
        })
        let user = new User("", "", "", "");
        if (item.owner) {

            user = new User(item.owner.id, item.owner.name, item.owner.role, item.owner.email);
        }
        const project = new Project(item.id, item.project_name, item.check_link, item.create_time, keywords, user);
        yield put({
            type: projectTypes.EDIT_PROJECT_ASYNC,
            data: project
        })
    }

}

function* watchRemoveProjectAsync(action: ActionWrapper) {

    const [result, error]: any[] = yield call(HookAPI, `${RANKING_PATH_BASE}/${action.data}`, null, methodTypes.DELETE, true);

    if (result) {
        yield put({
            type: projectTypes.REMOVE_PROJECT_ASYNC,
            data: action.data
        })
    }

}

function* watchAddKeywordAsync(action: ActionWrapper) {

    const project_id = action.data.id;
    const keylist: String[] = [];
    action.data.keywords.forEach((item: Keyword) => {

        keylist.push(item.keyword)

    })


    const [result, error]: any[] = yield call(HookAPI, `${RANKING_PATH_BASE}/${project_id}/addkeyword`, keylist, methodTypes.POST, true);

    if (result) {
        const item = result.data;


        let keywords: Keyword[] = [];
        item.keywords.forEach((keyword: any) => {
            keywords.push(new Keyword(keyword.id, keyword.keyword, keyword.currentRank, keyword.oldRank, keyword.bestRank, keyword.checkedLink, keyword.timeCreate, keyword.timeCheck, keyword.chart, keyword.notes, keyword.volume));
        })
        let user = new User("", "", "", "");
        if (item.owner) {

            user = new User(item.owner.id, item.owner.name, item.owner.role, item.owner.email);
        }
        const project = new Project(item.id, item.project_name, item.check_link, item.create_time, keywords, user);
        yield put({
            type: projectTypes.EDIT_PROJECT_ASYNC,
            data: project
        })
    }

}

function* watchCheckKeywordAsync(action: ActionWrapper) {

    yield put({
        type: applicationTypes.TOGGLE_CHECK_RANK_OVERLAY_ASYNC,
        data: checkStatusTypes.CHECKING
    })

    const project_id = action.data.id;
    const keylist: KeywordUpdate[] = [];

    action.data.keywords.forEach((element: Keyword) => {
        keylist.push(new KeywordUpdate(element.id, element.keyword, element.current_rank, element.old_rank, element.check_link, element.create_date, element.modify_date, element.volume));
    });


    const [result, error]: any[] = yield call(HookAPI, `${RANKING_PATH_BASE}/${project_id}/keywordchecking`, keylist, methodTypes.POST, true);


    if (result) {
        console.log(result)

        const list_key = result.data;

        let keywords: Keyword[] = [];
        list_key.forEach((keyword: any) => {
            keywords.push(new Keyword(keyword.id, keyword.keyword, keyword.currentRank, keyword.oldRank, keyword.bestRank, keyword.checkedLink, keyword.timeCreate, keyword.timeCheck, keyword.chart, keyword.notes, keyword.volume));
        })

        yield put({
            type: projectTypes.CHECK_KEY_PROJECT_ASYNC,
            data: {
                id: project_id,
                keywords: keywords
            }
        })
        yield put({
            type: applicationTypes.TOGGLE_CHECK_RANK_OVERLAY_ASYNC,
            data: checkStatusTypes.DONE
        })
    }

}

function* watchRemovekeywordAsync(action: ActionWrapper) {
    const data = action.data;
    const project_id = data.project_id;
    const keyword_id = data.keyword_remove_id;
    const [result, error]: any[] = yield call(HookAPI, `${RANKING_PATH_BASE}/${project_id}/removekeyword`, [keyword_id], methodTypes.DELETE, true);

    if (result) {
        const item = result.data;


        let keywords: Keyword[] = [];
        item.keywords.forEach((keyword: any) => {
            keywords.push(new Keyword(keyword.id, keyword.keyword, keyword.currentRank, keyword.oldRank, keyword.bestRank, keyword.checkedLink, keyword.timeCreate, keyword.timeCheck, keyword.chart, keyword.notes, keyword.volume));
        })
        let user = new User("", "", "", "");
        if (item.owner) {

            user = new User(item.owner.id, item.owner.name, item.owner.role, item.owner.email);
        }
        const project = new Project(item.id, item.project_name, item.check_link, item.create_time, keywords, user);
        yield put({
            type: projectTypes.EDIT_PROJECT_ASYNC,
            data: project
        })
    }
}

function* watchAddNewNoteAsync(action: ActionWrapper) {

    const sender_data = action.data;

    let listProject: Project[] = yield select(get_projects);
    let project = listProject.find(i => i.id === action.data.project);
    let sendbody = {
        key_id: sender_data.key_id,
        note: sender_data.note
    }
    const [result, error]: any[] = yield call(HookAPI, `${RANKING_PATH_BASE}/${sender_data.project}/add_note`, sendbody, methodTypes.POST, false);

    if (result) {
        const keyword = result.data;

        const newkeyword = new Keyword(keyword.id, keyword.keyword, keyword.currentRank, keyword.oldRank, keyword.bestRank, keyword.checkedLink, keyword.timeCreate, keyword.timeCheck, keyword.chart, keyword.notes, keyword.volume)

        if (project) {

            let keywords = project.keywords;

            const findedItem = keywords.find(i => i.id === newkeyword.id);
            if (findedItem) {
                project.keywords[keywords.indexOf(findedItem)] = newkeyword;
            }

            let user = new User("", "", "", "");
            if (project.owner) {

                user = new User(project.owner.id, project.owner.name, project.owner.role, project.owner.email);
            }
            const addProject = new Project(project.id, project.project_name, project.check_link, project.create_time, project.keywords, user);


            yield put({
                type: projectTypes.EDIT_PROJECT_ASYNC,
                data: addProject
            })
        }


    }
}

function* watchEditNoteAsync(action: ActionWrapper) {
    const sender_data = action.data;

    let listProject: Project[] = yield select(get_projects);
    let project = listProject.find(i => i.id === action.data.project);
    let sendbody = {
        id: sender_data.id,
        key_id: sender_data.key_id,
        note: sender_data.note
    }
    const [result, error]: any[] = yield call(HookAPI, `${RANKING_PATH_BASE}/${sender_data.project}/edit_note`, sendbody, methodTypes.POST, false);

    if (result) {
        const keyword = result.data;

        const newkeyword = new Keyword(keyword.id, keyword.keyword, keyword.currentRank, keyword.oldRank, keyword.bestRank, keyword.checkedLink, keyword.timeCreate, keyword.timeCheck, keyword.chart, keyword.notes, keyword.volume)

        if (project) {

            let keywords = project.keywords;

            const findedItem = keywords.find(i => i.id === newkeyword.id);
            if (findedItem) {
                project.keywords[keywords.indexOf(findedItem)] = newkeyword;
            }

            let user = new User("", "", "", "");
            if (project.owner) {

                user = new User(project.owner.id, project.owner.name, project.owner.role, project.owner.email);
            }
            const addProject = new Project(project.id, project.project_name, project.check_link, project.create_time, project.keywords, user);


            yield put({
                type: projectTypes.EDIT_PROJECT_ASYNC,
                data: addProject
            })
        }
    }
}

function* watchRemoveNoteAsync(action: ActionWrapper) {
    const sender_data = action.data;


    const [result, error]: any[] = yield call(HookAPI, `${RANKING_PATH_BASE}/${sender_data.project}/removenote/${sender_data.key_id}`, sender_data.list, methodTypes.DELETE, false);
    if (result) {
        const item = result.data;


        let keywords: Keyword[] = [];
        item.keywords.forEach((keyword: any) => {
            keywords.push(new Keyword(keyword.id, keyword.keyword, keyword.currentRank, keyword.oldRank, keyword.bestRank, keyword.checkedLink, keyword.timeCreate, keyword.timeCheck, keyword.chart, keyword.notes, keyword.volume));
        })
        let user = new User("", "", "", "");
        if (item.owner) {

            user = new User(item.owner.id, item.owner.name, item.owner.role, item.owner.email);
        }
        const project = new Project(item.id, item.project_name, item.check_link, item.create_time, keywords, user);
        yield put({
            type: projectTypes.EDIT_PROJECT_ASYNC,
            data: project
        })
    }
}

function* watchFetchProject() {
    yield takeLatest(projectTypes.FETCH_PROJECTS, watchFetchProjectAsync)
}

function* watchEditKeyword() {
    yield takeLatest(projectTypes.EDIT_RANK_KEYWORD, watchUpdateRankKeywordAsync)
}

function* watchCreateProject() {
    yield takeLatest(projectTypes.CREATE_PROJECT, watchCreateProjectAsync)
}

function* watchEditProject() {
    yield takeLatest(projectTypes.EDIT_PROJECT, watchEditProjectAsync)
}

function* watchRemoveProject() {
    yield takeLatest(projectTypes.REMOVE_PROJECT, watchRemoveProjectAsync)
}

function* watchAddKeyword() {
    yield takeLatest(projectTypes.ADD_KEYWORD, watchAddKeywordAsync)
}

function* watchCheckKeyword() {
    yield takeLatest(projectTypes.CHECK_KEY_PROJECT, watchCheckKeywordAsync)
}

function* watchRemoveKeyword() {
    yield takeLatest(projectTypes.REMOVE_KEYWORD, watchRemovekeywordAsync)
}

function* watchAddNewNote() {
    yield takeLatest(projectTypes.ADD_NEW_NOTE, watchAddNewNoteAsync)
}

function* watchEditNote() {
    yield takeLatest(projectTypes.EDIT_NOTE, watchEditNoteAsync)
}

function* watchRemoveNote() {
    yield takeLatest(projectTypes.DELETE_NOTE, watchRemoveNoteAsync)
}

export default function* projectSaga() {
    yield all([
        fork(watchFetchProject),
        fork(watchCreateProject),
        fork(watchEditProject),
        fork(watchRemoveProject),
        fork(watchAddKeyword),
        fork(watchCheckKeyword),
        fork(watchRemoveKeyword),
        fork(watchEditKeyword),
        fork(watchAddNewNote),
        fork(watchEditNote),
        fork(watchRemoveNote)
    ])
}