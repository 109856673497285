interface IKeyword {
    id: string;
    keyword: string;
    current_rank: Number;
    old_rank: Number;
    best_rank: Number;
    check_link: string;
    create_date: Number;
    modify_date: Number;
    chart: Object[];
    notes: Object[];
    volume: Number;
    projectId: String;
}


export default class Keyword implements IKeyword {
    public id: string;
    public keyword: string;
    public current_rank: Number;
    public old_rank: Number;
    public best_rank: Number;
    public check_link: string;
    public create_date: Number;
    public modify_date: Number;
    public chart: Object[];
    public notes: Object[];
    public volume: Number;
    public projectId: String;
    constructor(
        id: string,
        keyword: string,
        current_rank: Number,
        old_rank: Number,
        best_rank: Number,
        check_link: string,
        create_date: Number,
        modify_date: Number,
        chart: Object[],
        notes: Object[] = [],
        volume: Number,
        projectId: String = "") {
        this.id = id;
        this.keyword = keyword;
        this.current_rank = current_rank;
        this.old_rank = old_rank;
        this.check_link = check_link;
        this.create_date = create_date;
        this.modify_date = modify_date;
        this.chart = chart;
        this.best_rank = best_rank;
        this.notes = notes;
        this.volume = volume;
        this.projectId = projectId;
    }
}