import { useState, useEffect } from "react"
import { Container, Row, Form, FormGroup, Label, Input, Col, UncontrolledTooltip, Button, InputGroup, InputGroupAddon } from "reactstrap";
import Project from "../../../Object/project.object";
import { regex_domain } from "../../../utility/check_rank";
import message from "@davistran86/notification"

const ProjectEdit = (props) => {
    const [data, setData] = useState(props.data);
    const [name, setName] = useState(data.project_name);
    const [link, setLink] = useState(data.check_link)

    useEffect(() => {
        setData(props.data)
        setName(props.data.project_name)
        setLink(props.data.check_link)
    }, [props.data])

    const update_project = () => {

        const update_data = new Project(data.id, name ? name : data.project_name, link ? link : data.check_link, data.create_time, data.keywords, data.owner);
        props.edit(update_data);
    }

    
    const projectLink = () => {
        const domain = regex_domain(link);
        if (domain != false) {
            message.success({ title: "Hệ thống", description: "Domain đã đúng" }, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px"
            })
            setLink(domain);
        } else {
            message.warning({ title: "Lỗi domain", description: "Domain phải có dạng abc.xyz" }, {
                width: Math.min(window.innerWidth * 0.8, 400) + "px"
            })
        }
    }

    return (
        <Container fluid>
            <Row><Col><h3>Chỉnh sửa dự án</h3></Col></Row>
            <Row>
                <Form>
                    <FormGroup row className={"mb-3"}>
                        <Label for="example_name" sm={4}>Tên dự án</Label>
                        <Col sm={8}>
                            <Input type="text" name="example_name" placeholder="Tên dự án..." value={name} onChange={(e) => setName(e.target.value)} />
                        </Col>
                    </FormGroup>
                    <FormGroup row className={"mb-3"}>
                        <Label for="example_link" sm={4}>Link kiểm tra</Label>


                        <Col sm={8}>
                            {/* <Input type="text" name="example_link" placeholder="example: https://example.com" value={link} onChange={(e) => setLink(e.target.value)} /> */}

                            <div>
                                <InputGroup>
                                    <Input type="text" name="project_link" placeholder="ví dụ: example.com" id={"UncontrolledTooltipProjectLink"} onChange={(e) => setLink(e.target.value)} value={link} />
                                    <InputGroupAddon addonType="append">
                                        <Button type="button" color="success" onClick={() => projectLink()}>Kiểm tra domain</Button>
                                    </InputGroupAddon>
                                </InputGroup>

                                <UncontrolledTooltip placement="right" target="UncontrolledTooltipProjectLink" placement={"top"}>
                                    Kiểm tra domain để chắc chắn kiểm tra đúng
                                </UncontrolledTooltip>
                            </div>
                        </Col>
                    </FormGroup>
                    <FormGroup>
                        <Button onClick={() => update_project()} color={"success"}>Lưu chỉnh sửa</Button>
                    </FormGroup>
                </Form>
            </Row>
        </Container>

    )
}

export default ProjectEdit;