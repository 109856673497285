import { ActionWrapper } from "../../Object/redux.object";
import * as spinner_type from "../../constants/Spinner.constant";
import Spinner from "../../Object/Spinner.object";


const SpinnerRepository = (state: Spinner[] = [], action: ActionWrapper) => {


    const edit_spinner = (data: Spinner) => {

        console.log(data);
        const index = state.findIndex(item => item.id === data.id);

        state[index] = { ...data };

        return state;

    }

    const remove_spinner = (data: String) => {
        const index = state.findIndex(item => item.id === data);

        state.splice(index, 1)
        return state;

    }

    const filter_state = (a: Spinner[]) => {

        let arr: Spinner[] = []
        a.forEach((item) => {

            if (arr.filter((i) => i.id == item.id).length == 0) {
                arr.push(item)
            }
        })
        return [...arr];
    }

    switch (action.type) {
        case spinner_type.FETCH_SPINNER_ASYNC:
            state.push(...action.data);
            state = filter_state(state);
            return [...state];
        case spinner_type.CREATE_SPINNER_DATA_ASYNC:
            state.push(action.data)
            state = filter_state(state);

            return [...state]
        case spinner_type.EDIT_SPINNER_DATA_ASYNC:

            state = edit_spinner(action.data);

            return [...state];
        case spinner_type.REMOVE_SPINNER_DATA_ASYNC:

            state = remove_spinner(action.data);

            return [...state];
        default:
            state = filter_state(state);
            return state;
    }
}

export default SpinnerRepository;