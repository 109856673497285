import React, { Component } from 'react'
import Breadcrumbs from '../breadcrumb'

class Main extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toggleProfile: false,
            width: window.innerWidth,
            height: window.innerHeight
        }
    }


    updateDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };
    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
    }

    render() {
        const { crumbs, Component } = this.props;



        return (
            <div className="container w-100 pr-4">
                <div className="row">
                    <Breadcrumbs crumbs={crumbs} />
                </div>
                <Component {...this.props} />

            </div>

        )
    }
}

export default Main

