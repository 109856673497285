import { useEffect } from "react";
import { useState } from "react"
import { Line } from "react-chartjs-2";
import { Col, Container, FormGroup, Input, Label, Row, Table } from "reactstrap";

const MultiAnalytic = (props) => {

    const [data, setData] = useState(props.data);
    const [keyword_project, setKeywordProject] = useState([])
    const [selected_keyword, setSelectedKeyword] = useState([])
    const [tableCheck, setTableCheck] = useState([])
    const [searchKey, setSearchKey] = useState("");
    const [showdata, setShowData] = useState({});

    function getRandomColor() {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }

    const update_check_link = (item, action, index) => {
        let list = selected_keyword;
        let check_table = tableCheck;
        switch (action) {
            case "ADD":
                check_table[index] = true;
                list.push(item);
                break;
            case "REMOVE":
                check_table[index] = false;
                list = list.filter(i => item.id !== i.id)
                break;
        }
        setTableCheck([...check_table])
        setSelectedKeyword([...list]);
    }


    useEffect(() => {



        let labels = [];
        let datasets = [];
        selected_keyword.forEach(data => {



            let format_data = []
            data.chart.forEach((item, index) => {

                let date = new Date(item.date);
                let show_date = date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();

                format_data.push([show_date, item.rank])
            })
            // labels.push(data.keyword)
            const color = getRandomColor();
            datasets.push({
                label: data.keyword,
                data: format_data,
                fill: false,
                tension: 0.1,
                // borderColor:"red",
                borderColor: color,
                backgroundColor: color
            })
        })



        setShowData({
            labels: labels,
            datasets: datasets
        })

    }, [selected_keyword])

    useEffect(() => {

        setData(props.data)
        setKeywordProject(props.data.keywords)

    }, [props.data])

    const searchKeyword = (value) => {
        let keylist = data.keywords;
        keylist = keylist.filter(i => i.keyword.includes(value));

        setKeywordProject([...keylist])
        setSearchKey(value)

    }


    const options = {
        scales: {
            y: {
                ticks: {
                    beginAtZero: true,
                    reverse: true,
                    backdropPadding: {
                        x: 10,
                        y: 10
                    }
                },

                reverse: true,
                max: 100,
                step: 10,
                min: 0
            },
        },
        interaction: {
            intersect: false,
        }
    };

    return (


        <Container fluid>

            <FormGroup row className={"mb-3"}>
                <Label for="example_name" sm={4}>Tìm kiếm</Label>
                <Col sm={8}>
                    <Input type="text" name="example_name" placeholder="Tên từ khóa ..." value={searchKey} onChange={(e) => searchKeyword(e.target.value)} />
                </Col>
            </FormGroup>
            <Row style={{ height: "280px", overflow: "hidden", overflowY: "auto" }}>
                <div>
                    <Table >
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Từ khóa</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                keyword_project && keyword_project.map((item, index) => (
                                    <tr key={item.id} onClick={() => update_check_link(item, !tableCheck[index] ? "ADD" : "REMOVE", index)}>
                                        <td>
                                            <FormGroup check>
                                                <Label check>
                                                    <Input type="checkbox" value={1} onChange={(e) => update_check_link(item, e.target.checked ? "ADD" : "REMOVE", index)} checked={tableCheck[index]} />
                                                </Label>
                                            </FormGroup>
                                        </td>
                                        <td>{item.keyword}</td>
                                    </tr>
                                ))
                            }

                        </tbody>
                    </Table>

                </div>
            </Row>
            <hr className="dropdown-divider"/>
            <Row>
                <Container className={"w-100"} style={{ height: "500px" }}>
                    <Line data={showdata} options={options} />
                </Container>
            </Row>
        </Container>

    )

}


export default MultiAnalytic