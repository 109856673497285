import { all, fork } from "redux-saga/effects";
import applicationSaga from "./saga/application.saga";
import checkLinkSaga from "./saga/check_rank.saga";
import { documentEvent } from "./saga/document_custom_event.saga";
import projectSaga from "./saga/project.saga";
import spinnerSaga from "./saga/spinner.saga";

export default function* rootSaga() {
    yield (all([
        fork(applicationSaga),
        fork(projectSaga),
        fork(spinnerSaga),
        fork(checkLinkSaga),
        fork(documentEvent)
    ]))
}