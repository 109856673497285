import User from "./User.object";

interface ISpinner {
    id: string;
    name: string;
    type: string|null;
    data: string;
    owner: User;
    createDate:string;
    modifiDate:string;

}

export default class Spinner implements ISpinner {
    public id: string;
    public name: string;
    public type: string|null;
    public data: string;
    public owner: User;
    public createDate:string;
    public modifiDate:string;

    constructor(
        id: string,
        name: string,
        type: string|null,
        data: string,
        owner: User,
        createDate:string,
        modifiDate:string,) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.data = data;
        this.owner = owner;
        this.createDate = createDate;
        this.modifiDate = modifiDate;
    }
}