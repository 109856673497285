import AppSetting from "./appSetting.object";

interface IApplication {
    token: string;
    user_name: string;
    user_id: string;
    expired_date: string;
    oauth:Boolean;
    app_setting: AppSetting
}


export default class Application implements IApplication {
    public token: string;
    public user_name: string;
    public user_id: string;
    public expired_date: string;
    public oauth: Boolean;
    public app_setting: AppSetting

    constructor(
        user_id: string,
        user_name: string,
        token: string,
        expired_date: string) {
        this.token = token;
        this.user_name = user_name;
        this.user_id = user_id;
        this.expired_date = expired_date;
        this.oauth = false;
        if(token != null && token != undefined && token != ""){
            this.oauth = true;
        }
        this.app_setting = new AppSetting(false,false,"DONE");
    }
}